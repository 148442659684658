import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../Constant/Matcher";

const getToken = () => {
    const userToken = JSON.parse(localStorage.getItem("userData"))
    return userToken ? `Bearer ${userToken.token}` : ''
}


export const profileUploadApi = createApi({
  reducerPath: "profileUploadApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    mode: "cors",
    prepareHeaders: (headers) => {
        const token = getToken()
        if(token) {
            headers.set('Authorization', token)
        }
        return headers;
    }
  }),
  endpoints: (builder) => ({
    uploadImg: builder.mutation({
      query: (postData) => ({
        url: `update-profileimage`,
        method: "POST",
        body: postData,
      }),
    }), 
    uploadAdvise: builder.mutation({
      query: (postData) => ({
        url: `portfolio/clientadviseupdate`,
        method: "POST",
        body: postData,
      }),
    }),
  }),
});

export const {  useUploadImgMutation, useUploadAdviseMutation } = profileUploadApi;
