import React, { useState } from "react";
import data from "../../../Constant/Question.json";

const Question = (props) => {
const [error, setError] = useState("")   
  const handleCalculate = (e) => {
    e.preventDefault();
     // Check if all questions are answered
  const allQuestionsAnswered = data?.question?.every((res) => {
    const selectedOption = props.userResponses.find(
      (response) => response?.question === res?.Question
    );
    return selectedOption !== undefined;
  });

  if (!allQuestionsAnswered) {
    // Show an error message or take appropriate action
    setError('Please answer all questions before proceeding.');
    setTimeout(() => {
        setError("")
    }, 5000);
    return;
  }
  const calculatedTotalScore = props.userResponses.reduce(
    (total, response) => total + response.marks,
    0
  );
  if(calculatedTotalScore >= 360 && calculatedTotalScore <= 400){
      props.setScore("Very Aggressive")
  }
  else if(calculatedTotalScore >= 260 && calculatedTotalScore <= 350){
    props.setScore("Aggressive")
  }
  else if(calculatedTotalScore >= 130 && calculatedTotalScore <= 250){
    props.setScore("Moderate")
  }
  else if(calculatedTotalScore >= 80 && calculatedTotalScore <= 120){
    props.setScore("Defensive ")
  }
  else{
    props.setScore("")
  }
    props.setShowQuestion(false);
    props.setShowThirdScreen(true);
  };

  return (
    <>
      <div className="modal-box">
        <article className="modal-container">
          <header className="modal-container-header">
            <span className="modal-container-title">
              Risk Profile Calculator
            </span>
            <p className="text-danger mt-1 text-center">{error}</p>
          </header>
          <section className="modal-container-body rtf">
            <form action="">
              {data?.question?.map((res, questionIndex) => {
                return (
                  <>
                    <div className="questions" key={res?.id}>
                      <div className="d-flex">
                        <span>{questionIndex + 1}&#41; </span>
                        <p className="mb-2 ms-2">{res?.Question}</p>
                      </div>
                      {res?.Options?.map((i, index) => {
                        return (
                          <div
                            className="d-flex align-items-center mb-3 "
                            key={i?.ansId}
                          >
                            <input
                              type="radio"
                              name={`questionOptions_${res?.id}`}
                              id={`reverseCheck${i?.ansId}`}
                              value={i?.Option}
                              onChange={() =>
                                props.handleAnswerChange(questionIndex, i.Option, i.score)
                              }
                            
                            />
                            <label htmlFor={`reverseCheck${i?.ansId}`}>
                              {" "}
                              {i?.Option}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                    <hr />
                  </>
                );
              })}
            </form>
          </section>
          <footer className="modal-container-footer">
            <button className="button is-ghost" onClick={handleCalculate}>
              Calculate Risk
            </button>
          </footer>
        </article>
      </div>
    </>
  );
};

export default Question;
