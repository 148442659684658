
// Current Portfolio Value
export const calculateCurretValue = (data) => {
  return data?.data?.reduce((acc, result) => {
  const currentprice = result?.stocklist[0]?.currentprice;
  return acc + (currentprice ? currentprice * result.qty : 0);
}, 0);
}


export const calculateFundInvested = (data) => { 
  return data?.investment?.reduce((acc, i) => {
  const transaction = parseFloat(i?.transacation) || 0;
  return acc + transaction;
}, 0);
}

// Holding Period
export const calculateMonths = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  const yearDifference = end.getFullYear() - start.getFullYear();
  const monthDifference = end.getMonth() - start.getMonth();

  // Add 1 to include the start month in the count
  return yearDifference * 12 + monthDifference + 1;
};


export const panRegex = /^(?=.{1,10}$)[A-Z]{5}[0-9]{1,4}[A-Z]{1}$/;

export const aadharRegex = /^\d{1,12}$/;

export const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); 
    const year = date.getFullYear();
  
    const formattedDate = `${day}-${month}-${year}`;
  
    return formattedDate;
  };

  export function formatIndianNumber(num) {
    if (num === undefined || num === null) return '';
    
    const isNegative = num < 0;
    const absoluteNum = Math.abs(num);
    
    const numStr = absoluteNum.toFixed(2); 
    const [integerPart] = numStr.split('.');
    
    const lastThreeDigits = integerPart.slice(-3);
    const otherDigits = integerPart.slice(0, -3);
    const formattedIntegerPart = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + (otherDigits ? ',' : '') + lastThreeDigits;
    
    const formattedNum = `${formattedIntegerPart}`;
  
    return isNegative ? `-${formattedNum}` : formattedNum;
}
