import React, { useEffect } from 'react'
import doc from '../../images/Technocraft Engineering.pdf'
import AOS from "aos";
import "aos/dist/aos.css";
const WinnerCard = ({title, price, cmp, sector, img, imgWidth }) => {
  useEffect(() => {
    AOS.init({
      delay: 100, // values from 0 to 3000, with step 50ms
      duration: 600, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false,
    });
  }, []);


  return (
    <a href={doc} target='_blank' rel='noreferrer' >
    <div className='hover_card shadow border-0 rounded-2 card d-flex flex-column  justify-content-between align-items-start p-3 position-relative ' data-aos="flip-up">
    <div className='text-white px-2 py-1 top-0 holding-text'><span>Holding</span>
   
    </div>
        <div className=' d-flex justify-content-between align-items-center w-100'>
                  <img src={img} alt="" className=' object-fit-contain' width={imgWidth}  />
        </div>
        <div className='w-100'>
            <h6 className='text-center  fw-semibold  text-uppercase'>{title}</h6>
            <p className=' text-center text-dark-emphasis mb-3' style={{fontSize: "14px"}}>Sector:{sector}</p>
            <div className='d-flex justify-content-between'>
            <span className=' fw-semibold'>Advice Price:  ₹ {price}</span>
            <span className=' fw-semibold'>CMP: ₹ {cmp}</span>

            </div>
        </div>
       
    </div>
    </a>
  )
}

export default WinnerCard