import React from "react";
import { MdOutlineWatchLater } from "react-icons/md";
import { FaPlus } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { useAllBlogQuery } from "../../../redux/API/blogApi";
import { blogImg, imgUrl } from "../../constant/constant";
import { formatDate, truncate } from "../../constant/utils";
import { LazyLoadImage } from 'react-lazy-load-image-component'
import img1 from '../../images/placeholder.jpg'
const Blog = () => {
  const {data} = useAllBlogQuery()  



  return (
    <>
      <div className="container mt-5 ">
        <h2 className="heading text-center ">Latest Updates</h2>
        <div className="row pt-3 pb-5 gy-2">
        {
              data && data?.data?.slice(0, 4).map((i) => (  
          <div className="col-lg-3 col-md-6" key={i?._id}>
              <Link to={`/blog/${i?._id}`}>
            <div className="card  ">
           <div className="position-relative latest-update">
             <LazyLoadImage
            className='w-100'
            src={`${imgUrl}${blogImg}${i?.image}`}
            placeholderSrc={img1}
            alt={""}
            height={200}
          />
             <div className="overlay-shadow position-absolute  w-100  h-100  start-0  top-0  align-items-center  justify-content-center  text-white  fs-2">
             <FaPlus />
             </div>
             </div>
              <div className="card-body py-3  blog-card-body">
                <div className="border-line mb-3"></div>
                <h5 className="card-title">
                {truncate(i?.title, 5)}
                </h5>
               
                <span className="d-flex align-items-center"><MdOutlineWatchLater />{formatDate(i?.createdAt)}</span>
              </div>
            </div>
            </Link>
          </div>
              ))}
        </div>
      </div>
    </>
  );
};

export default Blog;
