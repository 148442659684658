import React from "react";
import { FiCheckCircle } from "react-icons/fi";
import pdf from '../../images/July2024.pdf'
const MidBanner = () => {
  return (
    <>
      <div className="mid-banner position-relative d-flex align-items-center mb-4">
        <div className="container text-white  ">
          <div className="row  ">
            <div className="col-12">
              <div>
              
              </div>
              <h2 className="green-txt"><FiCheckCircle className="me-3" />INVESTMENT ADVISORY</h2>
              <h2 className=" text-white ms-5">Number of complaints - July 2024</h2>
            </div>
            <div className="col-12 my-4">
              <div className="row">
                <div className="col-2"><p><strong className="fs-1 bg-transparent">0</strong><br/> In the Beginning of the month</p></div>
                <div className="col-2"><p><strong className="fs-1 bg-transparent">0</strong><br/> Received During the month</p></div>
                <div className="col-2"><p><strong className="fs-1 bg-transparent">0</strong><br/> Resolved during the month</p></div>
                <div className="col-2"><p><strong className="fs-1 bg-transparent">0</strong><br/> Pending at the end of month</p></div>
              </div>
            </div>
            <div className="col-12">
              <p className="mb-1">View complete grievances status <a target="_blank" href={pdf} rel="noreferrer" className="text-primary text-decoration-underline">here</a></p>
              <p>
                {" "}
                In case of any grievances or complaints please write to us on
                grievances@brightermind.in
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MidBanner;
