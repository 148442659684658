import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { Api_url } from '../../front/constant/constant'


export const galleryApi = createApi({
    reducerPath: "galleryApi",
    baseQuery: fetchBaseQuery({baseUrl: `${Api_url}`}),
    endpoints: (builder) => ({
        gallery: builder.query({
            query: () => ({
             url: "gallery",
             method: "GET",
            })
         }),
         galleryList: builder.query({
            query: (id) => ({
             url: `folderimages?folderid=${id}`,
             method: "GET",
            })
         }),
         
    })
})

export const { useGalleryQuery, useGalleryListQuery } = galleryApi