import React, { useState } from "react";
import video from "../../images/brighter mind bg vid.mp4";
import { LazyLoadImage } from "react-lazy-load-image-component";
import banner from "../../images/Untitled-design_1-1536x864.jpeg";
import { Link } from "react-router-dom";
import { useBannerContactMutation } from "../../../redux/API/pageApi";
import toast, { Toaster } from 'react-hot-toast';


const Banner = () => {
  const [infoData, setInfoData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    captcha: ""
  })
  const [postData] = useBannerContactMutation()
  const [error, setError] = useState("")
  const generateCaptcha = () => {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let captcha = "";
    for (let i = 0; i < 6; i++) {
      captcha += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return captcha;
  };

  const [captcha, setCaptcha] = useState(generateCaptcha());

  const refreshCaptcha = () => {
    setCaptcha(generateCaptcha());
  };

  const handleChange = (e) => {
    const {name, value} = e.target
    setInfoData({...infoData, [name]: value})
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if(infoData.captcha !== captcha){
      setError("Invalid Captcha")
      setTimeout(() => {
        setError("")
      }, 3000);
      return;
    }
    const data = {
      name: infoData.name,
      email: infoData.email,
      mobile: infoData.phone,
      message: infoData.message,
      template: "contact"
    }
    postData(data).then((res) => {
      if (res.error) {
        toast.error(res.error.message)
        } else {
          toast.success("Message Sent Successfully")
          setInfoData({
            name: "",
            email: "",
            phone: "",
            message: "",
            captcha: ""
            })
            
            }
            })
  }

  return (
    <>
    <Toaster position="top-right"/>
      <div className="banner position-relative ">
        <div className=" position-relative h-100">
          <LazyLoadImage
            src={banner}
            height={500}
            alt="Image Alt"
            className="w-100"
          />
          <video
            autoPlay
            muted
            loop
            src={video}
            className=" position-absolute  top-0  start-0 "
          ></video>
        </div>
        <div
          className="banner-content position-absolute   top-0 h-100 w-100"
          style={{ zIndex: "3" }}
        >
          <div className="container h-100 ">
            <div className="row h-100">
              <div className="col-lg-6 col-12 text-white d-flex  flex-column  text-center justify-content-center pb-lg-5 ">
                <h1 className="mb-3 mb-lg-5">BRIGHTER MIND</h1>
                <p className="mb-3 fs-5 text-center">
                  SEBI Registered Investment Advisor{" "}
                </p>
                <p className=" text-center fs-5 mb-lg-5">
                  "Invest to create wealth based on <br /> time-tested philosophy with
                  safety of capital"
                </p>
              </div>
              <div className="col-lg-6 text-white d-flex  flex-column align-items-center align-items-lg-end justify-content-center ">
                <form className="form w-100" onSubmit={handleSubmit}>
                  <p className="title mb-0">Contact Now </p>
                  <p className="text-danger mb-1">{error}</p>
                  <label>
                    <input
                      className="input"
                      type="text"
                      placeholder="Full Name"
                      id="name"
                      name="name"
                      required
                      onChange={handleChange}
                      value={infoData.name}
                    />
                  </label>

                  <label>
                    <input
                      className="input"
                      type="text"
                      placeholder="Mobile"
                      id="phone"
                      name="phone"
                      maxLength={10}
                      required
                      onChange={handleChange}
                      value={infoData.phone}
                    />
                  </label>
                  <label>
                    <input
                      className="input"
                      type="email"
                      placeholder="Email"
                      id="email"
                      name="email"
                      required
                      onChange={handleChange}
                      value={infoData.email}
                    />
                  </label>

                  <label>
                    <textarea
                      name="message"
                      id="message"
                      cols="30"
                      rows="3"
                      className="w-100"
                      placeholder="Message"
                      required
                      onChange={handleChange}
                      value={infoData.message}
                    ></textarea>
                  </label>
                  <div className=" d-flex justify-content-between align-items-center">
                    <span>
                      {captcha}{" "}
                      <Link
                        className=" bg-transparent border-0 text-white"
                        onClick={refreshCaptcha}
                      >
                        <i className="fa-solid fa-rotate-right ms-3"></i>
                      </Link>
                    </span>
                    <label className="w-50">
                      <input
                        className="input "
                        type="text"
                        placeholder="Enter Captcha *"
                        id="captcha"
                        name="captcha"
                        required
                        onChange={handleChange}
                      value={infoData.captcha}
                      />
                    </label>
                  </div>
                  <button className="submit" type="submit">Submit</button>
                </form>
              </div>
            </div>
          </div>
          <div className=" position-absolute  bottom-0 svg-box w-100  ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 2600 131.1"
              preserveAspectRatio="none"
              className="w-100"
            >
              <path
                fill="#9BC616"
                className="elementor-shape-fill"
                d="M0 0L2600 0 2600 69.1 0 0z"
              />
              <path
                fill="#9BC616"
                className="elementor-shape-fill"
                style={{ opacity: "0.5" }}
                d="M0 0L2600 0 2600 69.1 0 69.1z"
              />
              <path
                fill="#9BC616"
                className="elementor-shape-fill"
                style={{ opacity: "0.25" }}
                d="M2600 0L0 0 0 130.1 2600 69.1z"
              />
            </svg>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
