import React, { useContext, useState } from 'react'
import "../Pages/Style/dashboard.css";
import "../../App.css";
import { Bar } from 'react-chartjs-2'
import { Doughnut } from 'react-chartjs-2'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import LogoutCard from '../Components/Card/LogoutCard';
import Footer from '../Components/Footer/Footer';
import SideBar from '../Components/SideBar/SideBar';
import TextLinkExample from '../Components/NavBar/NavBar';
import { SidebarContext } from '../../context/SidebarContext';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
const FranchiseDashboard = () => {
const { isSiderbaropen } = useContext(SidebarContext);
const [showLogout, setShowLogout] = useState(false);


const doughnutGraphData = {
    labels: ['Large Cap', 'Mid Cap', 'Small Cap'],
    datasets: [
      {
        label: 'Revenue in $',
        data: [20000, 25000, 18000],
        backgroundColor: ['#ea7e90', '#a84926', '#e28743'],
        hoverBackgroundColor: ['#FF6384AA', '#36A2EBAA', '#FFCE56AA'],
      }
    ]
  };

  const barGraphData = {
    labels: ['Stock A', 'Stock B', 'Stock C', 'Stock D', 'Stock E'],
    datasets: [
      {
        label: 'Stock',
        data: [120, 150, 180, 90, 110],
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'],
      }
    ]
  };

  const options = {
    responsive: true,
    indexAxis: 'y', 
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Top 10 Industries by Current Portfolio Value',
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          callback: function(value) {
            return value + '%';
          },
          stepSize: 10,
      },
    },
    },
  };

  return (
   <>
   <div className="App">
        <SideBar />
        <div className={!isSiderbaropen ? "pages" : "pages-change"}>
          <TextLinkExample setShowLogout={setShowLogout} />
          <div className="HomeCard">
          <div className="col-md mx-4">
              <div className="row ">
                <div className="col-xl-4 col-md-6 px-lg-1">
                  <div className="card static-1 row-1">
                    <div className="mx-4">
                      <h5 className="card-title">Current Portfolio Value</h5>
                      <h2 className="Values">
                      Rs. 66,77,4
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-md-6  px-lg-1">
                  <div className="card static-2 row-1">
                    <div className="mx-4">
                      <h5 className="card-title">Total Fund Invested</h5>
                      <h2 className="Values">
                      Rs. 66,77,4
                        </h2>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-md-6  px-lg-1">
                  <div className="card static-3 row-1">
                    <div className="mx-4">
                      <h5 className="card-title">No. of Clients</h5>
                      <h2 className="Values">
                      20
                       </h2>
                    </div>
                  </div>
                </div>
               
                <div className="col-xl-4 col-md-6 px-lg-1 pe-lg-0 ">
                  <div className="card static-4 " style={{borderBottom: "14px solid #5A4343"}}>
                    <div className="mx-4">
                      <h5 className="card-title">Absolute Return</h5>
                      <h2 className="Values">
                      20%
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-md-6 px-lg-1">
                  <div className="card static-1 " style={{borderBottom: "14px solid #5A4343"}}>
                    <div className="mx-4">
                      <h5 className="card-title">Number of Channel Partners</h5>
                      <h2 className="Values">
                      40
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-md-6 px-lg-1">
                  <div className="card static-1 " style={{borderBottom: "14px solid #5A4343"}}>
                    <div className="mx-4">
                      <h5 className="card-title">No. of Client Referred</h5>
                      <h2 className="Values">
                      40
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="PieChart-Table row gy-3 mx-4 mt-4">
              <div className=" PieChart col-lg-12">
                <div className="row gy-3">
                  <div className="PieChartTitle d-flex m-2">
                    <h5>PortFolio Strategy: </h5>
                    <h5
                    className='mt-3'
                      style={{
                        backgroundColor: "#9DC913",
                        padding: "5px 10px",
                        borderRadius: "4px",
                        color: "white",
                      }}
                    >
                      {" "}
                      E-50 Strategy{" "}
                    </h5>
                  </div>
                  <div className="col-lg-5">
                  <div style={{height: "300px"}}>
                  <Doughnut data={doughnutGraphData}/>
                  </div>
                  </div>
                  <div className="col-lg-7">
                  <div style={{height: "300px"}}>
                    <Bar data={barGraphData} options={options}  style={{width: "100%", height: "300px"}}/>
                    </div>
                  </div>
                  <div
                    className="PieChartFooter mt-4"
                    style={{
                      textAlign: "center",
                      fontSize: "18px",
                      fontWeight: "bold",
                      color: "#78766f",
                    }}
                  >
                    <div className="text-white py-1" style={{backgroundColor: "#9DC913"}}>
                      No. of Stocks in Portfolio:{" "}
                     
                        <strong>30</strong>
                     
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
          <Footer/>
          </div>
          </div>
          {showLogout && <LogoutCard setShowLogout={setShowLogout} />}
   </>
  )
}

export default FranchiseDashboard