import React from 'react'

const Payment = (props) => {
  return (
    <div className="first-screen">
         <div className="form register">
                <div className="title text-center mb-3">Payment Details</div>
                <p className="mb-1 text-black d-flex justify-content-between"><span>Investement Amount:</span>
                <span>Rs.{props.invamount}</span>
                </p>
                <p className="mb-1 text-black d-flex justify-content-between"><span>Advisory Fee: <span style={{fontSize: '10px'}}>(1.25% of AVA for 6 months)</span></span> 
                <span>Rs.{props.advisoryFee}</span></p>
                <p className="mb-1 text-black d-flex justify-content-between"><span>GST:</span>
                <span>Rs.{props.gstAmount}</span>
                </p>
                <p className="mb-1 text-black d-flex justify-content-between"><span>Total:</span>
                <span>Rs.{props.totalAmount}</span>
                </p>
               
                <div className='d-flex justify-content-center'>
                <button
                      className="action me-2 text-white"
                      onClick={props.handleRegistration}
                      disabled={props.submitting}
                      style={{backgroundColor: "#9DC913", 
                        marginTop: "24px",
                        padding: "12px 16px",
                        borderRadius: "8px",
                        border: "none"
  
                      }}
                    >
                      {props.submitting ? "Submitting..." : "SKIP"}
                    </button>
                    
                    <button class="text-white text-uppercase " style={{backgroundColor: "#002E5B", 
                      marginTop: "24px",
                      padding: "12px 16px",
                      borderRadius: "8px",
                      border: "none"

                    }} onClick={(e) => {
                      e.preventDefault(); 
                      props.displayRazorpay();
                    }}>Make Payment</button>
                </div>
                </div>
    </div>
  )
}

export default Payment