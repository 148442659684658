import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import HoverVideoPlayer from 'react-hover-video-player';
import thumbnail from '../../images/yearimagecircle.png'
import video from "../../images/brighter mind bg vid.mp4";
import icon from '../../images/arrowright.svg'
import AOS from "aos";
import "aos/dist/aos.css";
const About = () => {

  useEffect(() => {
    AOS.init({
      delay: 100, // values from 0 to 3000, with step 50ms
      duration: 600, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false,
    });
  }, []);


  return (
    <>
      <div className="container mt-5 pb-4">
        <h2 className="heading text-center ">About us</h2>
        <div className="row pt-3 pb-5 gy-3">
        <div className="col-lg-5 rounded-circle overflow-hidden pt-4">
           <div className="video-box d-flex justify-content-center position-relative rounded-circle"  data-aos="fade-right">
           <HoverVideoPlayer
      videoSrc={video}
      videoStyle={{
        borderRadius: "100%",
        height: "240px",
        width: "240px",
       
      }}
      pausedOverlay={
        <img
          src={thumbnail}
          alt=""
          style={{
            height: "280px",
           width: "280px",
            top: "-8%",
            left: "-9%",
            position: "absolute"

          }}
        />
      }
      loadingOverlay={
        <div className="loading-overlay">
          <div className="loading-spinner" />
        </div>
      }
    />
           </div>
          </div>
          <div className="col-lg-7   mt-5 mt-lg-3 mt-md-3 pb-2 overflow-hidden">
            <div data-aos="fade-left">
            <h3 className=" mb-3">
            Your search for the equity investing route ends here!
            </h3>
            <p className=" text-dark-emphasis about-para ">
            Brighter Mind Equity Advisor is a <strong>SEBI Registered Investment Advisor</strong>. Our sole focus is to help investors <strong>create wealth</strong> in long term. We believe that <strong>Equity is the best Asset class</strong> if we manage the risk efficiently and give it <strong>time to flourish</strong>.  
            </p>
            <p className=" text-dark-emphasis fs-5 fw-semibold">
              <img src={icon} alt="" width={30} /> Join us to reap the benefit of equity investing!
            </p>
            <Link className=" login-btn text-center py-2 px-4 text-white ">More About us</Link>
            </div>
          </div>
        
        </div>
      </div>
     
    </>
  );
};

export default About;
