import React from 'react'
import Navbar from '../../components/partial/Navbar'
import Footer from '../../components/partial/Footer'
import img1 from '../../images/Rajeev.jpg'
import { useAllBlogQuery } from '../../../redux/API/blogApi'
import { Link } from 'react-router-dom'
import { formatDate, truncate } from '../../constant/utils'
import { MdOutlineWatchLater } from "react-icons/md";
import { FaPlus } from "react-icons/fa6";
const TeamOne = () => {
const {data} = useAllBlogQuery()  

function createMarkup2(content) {
    const sanitizedContent = content?.replace(/<p>&nbsp;<\/p>*>/g, "");
    return { __html: sanitizedContent };
 }

  return (
    <>
    <Navbar/>
    <section className='mt-3 py-5 border border-t-2 border-b-2 '>
    <div className="container">
    <h4 className='mb-0'>CA Mr. Rajeev Ranjan</h4>
    <p className='fw-semibold text-black-50'>Founder & CMD</p>
        <div className="row gy-2 team-detail">
            <div className="col-lg-4">
              
                <img src={img1} alt="CA Rajeev Ranjan" className="object-fit-contain" width={300}/>
            </div>
            <div className="col-lg-8 team-detail">
                <p>
                Rajeev Ranjan, CA, is Founder and CMD of the Brighter Mind. Prior to this in his role as Chartered Accountant in practice he contributed to the growth of companies in the area of business planning, MIS, company restructuring and even in domains other than finance. He has experienced equity market as an investor and came across with all the phases of the equity market in last 25 years. He was guiding for investment in due course of CA in practice. His faith in Indian economy and understanding of value investment in equity market helped many investors in their journey of wealth creation. He is committed to establish Brighter Mind as symbol of ethical practice in the area of equity portfolio advisory.
                </p>
                <p >He qualified his CA in the year 2001 and got fellowship of CA in year 2006. He graduated in commerce from Delhi University. He has completed his schooling form, “St Michael’s High School – Patna”.</p>
            </div>
        </div>
    </div>
    </section>
    <section className='my-5'>
        <div className="container">
            <h3>Blog</h3>
            <div className="row mt-3 gy-3">
            {
              data && data?.data?.slice(0, 2).map((i) => (  
        <div className="col-md-6" key={i?._id}>
            <div className="card  ">
             <Link to={i?._id} className=" position-relative latest-update">
             <div className="overlay-shadow position-absolute  w-100  h-100  start-0  top-0  align-items-center  justify-content-center  text-white  fs-2">
             <FaPlus />
             </div>
             </Link>
              <div className="card-body py-3 blog-card-body" style={{height: "200px"}}>
               
                <h5 className="card-title mb-3">
                 {truncate(i?.title, 5)}
                </h5>
               <p className='team-blog' dangerouslySetInnerHTML={createMarkup2(truncate(i?.description, 38))}></p>
                <span><MdOutlineWatchLater />{formatDate(i?.createdAt)}</span>
                <Link to={`/blog/${i?._id}`} className='read-more-btn float-end py-1 px-2 rounded-2'>Read More</Link>
              </div>
            </div>
          </div>
              
              ))
            }
            </div>
        </div>
    </section>
    <Footer/>
    </>
  )
}

export default TeamOne