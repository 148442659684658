import React, { useState } from "react";
import { css } from "@emotion/css";
import HoverVideoPlayer from "react-hover-video-player";
import "./Style/managersdesk.css";
import { Feedback } from "./Feedback";
import SideBar from "../Components/SideBar/SideBar";
import TextLinkExample from "../Components/NavBar/NavBar";
import { useContext } from "react";
import { SidebarContext } from "../../context/SidebarContext";
import LogoutCard from "../Components/Card/LogoutCard";
import Footer from "../Components/Footer/Footer";

const ManagersDesk = () => {
  const { isSiderbaropen } = useContext(SidebarContext);
  const [showLogout, setShowLogout] = useState(false);
  // const bigBuckBunnySrc =
  // "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4";

  return (
    <>
      <div className="App">
        <SideBar />
        <div className={!isSiderbaropen ? "pages" : "pages-change"}>
          <TextLinkExample setShowLogout={setShowLogout} />
          <div className=" ManagersDesk">
            <div className="container shadow-none mx-4 my-3 py-2">
              <div className="row gy-3 align-items-center">
                <div className="col-lg-7 card pt-2">
                  <h4 className="mb-0">CA Rajeev Ranjan</h4>
                  <h6>Founder & CMD </h6>
                  <p className=" text-dark-emphasis" style={{fontSize: "14px"}}>
                    With over 25 years of hands-on experience in the Indian
                    equity markets, Rajeev has navigated through all market
                    phases, establishing himself as a trusted advisor and
                    investor. His deep faith in Indian economy and strategic
                    understanding of value investing has empowered many
                    investors on their wealth creation journey. Under his
                    leadership, Brighter Mind stands as a lighthouse of
                    integrity and ethical practice in the equity advisory space,
                    reflecting our commitment to building trust and delivering
                    enduring value to our clients.
                  </p>
                </div>
                <div className=" ManagersDeskVideos col-lg-5">
                  <HoverVideoPlayer
                    className="HoverVideoPlayer mx-4"
                    videoSrc={require("../Components/photos/forest.mp4")}
                    pausedOverlay={
                      <div
                        className={css`
                          background-color: #5a4343;
                          top: 0;
                          left: 0;
                          width: 100%;
                          height: 100%;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        `}
                      >
                        <div
                          className={css`
                            font-family: sans-serif;
                            text-align: center;
                          `}
                        >
                          <h4>Play Video</h4>
                          <i className="fa-solid fa-circle-pause fa-beat-fade fa-2xl"></i>
                        </div>
                      </div>
                    }
                  />
                </div>
                <div className=" ManagersDeskVideos col-lg-5">
                  <HoverVideoPlayer
                    className="HoverVideoPlayer mx-4"
                    videoSrc={require("../Components/photos/forest.mp4")}
                    pausedOverlay={
                      <div
                        className={css`
                          background-color: #5a4343;
                          top: 0;
                          left: 0;
                          width: 100%;
                          height: 100%;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        `}
                      >
                        <div
                          className={css`
                            font-family: sans-serif;
                            text-align: center;
                          `}
                        >
                          <h4>Play Video</h4>
                          <i className="fa-solid fa-circle-pause fa-beat-fade fa-2xl"></i>
                        </div>
                      </div>
                    }
                  />
                </div>
                <div className="col-lg-7 card pt-2">
                  <h4 className="mb-0">Mr. Manoj Bandre</h4>
                  <h6>Founder & CEO</h6>
                  <p className=" text-dark-emphasis" style={{fontSize: "14px"}}>
                    With nearly a decade of experience in the Indian equity
                    market, Manoj has developed a reputation as a sharp and
                    insightful fund manager, known for his disciplined approach
                    and unwavering focus on value investing. Manoj's deep
                    understanding of value investment, combined with his talent
                    for tuning out market noise, has led to his success in
                    consistently delivering superior returns. His ideology of
                    investment revolves around—independent thinking, ethical
                    corporate governance, consistent quality earnings, patience,
                    conviction, and strategic timing—underscores his
                    effectiveness as a fund manager.
                  </p>
                </div>
                <div className="col-lg-7 card pt-2">
                  <h4 className="mb-0">Mr. Shashi Kant</h4>
                  <h6>Principal Officer & Head of Research</h6>
                  <p className=" text-dark-emphasis" style={{fontSize: "14px"}}>
                    He brings nearly a decade of expertise in Investment
                    Management and Equity Research to Brighter Mind Equity
                    Advisors. Before joining the firm, Shashi was associated
                    with BLB Limited and Perfect Research, where he managed
                    proprietary funds and conducted in-depth equity research for
                    family offices. His in-depth sectoral knowledge and
                    process-driven approach provide valuable insights and a
                    unique perspective in fund management. Shashi holds a
                    Master’s Degree in Financial Analysis and is a CFA (ICFAI)
                    from the Institute of Chartered Financial Analysts of India,
                    solidifying his credentials as a trusted expert in the
                    equity market.
                  </p>
                </div>
                <div className=" ManagersDeskVideos col-lg-5">
                  <HoverVideoPlayer
                    className="HoverVideoPlayer mx-4"
                    videoSrc={require("../Components/photos/forest.mp4")}
                    pausedOverlay={
                      <div
                        className={css`
                          background-color: #5a4343;
                          top: 0;
                          left: 0;
                          width: 100%;
                          height: 100%;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        `}
                      >
                        <div
                          className={css`
                            font-family: sans-serif;
                            text-align: center;
                          `}
                        >
                          <h4>Play Video</h4>
                          <i className="fa-solid fa-circle-pause fa-beat-fade fa-2xl"></i>
                        </div>
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
        <Feedback />
      </div>
      {showLogout && <LogoutCard setShowLogout={setShowLogout} />}
    </>
  );
};

export default ManagersDesk;
