import React from 'react'
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import Footer from '../../components/partial/Footer';
import Navbar from '../../components/partial/Navbar';
const InvestorGrievance = () => {
  return (
    <>
    <Navbar/>
    <div className="py-5 contact-header">
    <div className="  ps-5 text-white  ">
      <div>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link> <MdKeyboardArrowRight />
            </li>
            <li className="breadcrumb-item text-white " aria-current="page">
            INVESTOR GRIEVANCE
            </li>
          </ol>
        </nav>
      </div>
      <div>
        <h1>INVESTOR GRIEVANCE</h1>
      </div>
    </div>
  </div>
  <div className="container my-5 ">
    <h1 className=" text-center heading">POLICY ON INVESTOR GRIEVANCE</h1>
    <ol>
      <li className='mb-3' style={{listStyleType: "unset"}}>In case of any grievance / complaint, an investor should approach the Brighter Mind
Equity Advisor Private Limited (Investment Advisor) through the Phone
9911005770 and/or Email grievances@brightermind.in</li>
<li className='mb-3' style={{listStyleType: "unset"}}>Brighter Mind Equity Advisor Private Limited shall ensure that the grievance is
resolved within 30 days from receiving the same.
</li>
<li className='mb-3' style={{listStyleType: "unset"}}>IA will also ensure to maintain the records as per the Annexure B of the SEBI
Circular SEBI/HO/IMD/IMD-II CIS/P/CIR/2021/0686 dated 13th December, 2021
and display on its website.</li>
<li className='mb-3' style={{listStyleType: "unset"}}>If the investor’s complaint is not redressed satisfactorily, Investor may lodge a
complaint with SEBI on SEBI’s 'SCORES' portal which is a centralized web based
complaints redressal system. SEBI takes up the complaints registered via SCORES
with the concerned intermediary for timely redressal. SCORES facilitates tracking
the status of the complaint. The Link for Scores is <br />
<a className='text-primary text-decoration-underline' href="https://scores.gov.in/scores/Welcome.html" target='_blank' rel='noreferrer'>https://scores.gov.in/scores/Welcome.html</a>
</li>
<li className='mb-3' style={{listStyleType: "unset"}}>With regard to physical complaints, investors may send their complaints to: Office of
Investor Assistance and Education, Securities and Exchange Board of India, SEBI
Bhavan, Plot No. C4-A, ‘G’ Block, Bandra-Kurla Complex, Bandra (E), Mumbai - 400
051.</li>
    </ol>
  </div>
  <Footer/>
  </>
  )
}

export default InvestorGrievance