import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { baseUrl } from "../../Constant/Matcher";



export const loginApi = createApi({
    reducerPath: 'loginApi',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        mode: "cors",
    }),
    endpoints : (builder) => ({
        userLogin: builder.query({
            query:() => ({
                url: `login`,
                method:"GET",
            })
        })
    })
})

export const { useUserLoginQuery }  = loginApi