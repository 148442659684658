

export const formatDate = (date) => {
    const currentDate = new Date(date)
    const day = String(currentDate.getDate()).padStart(2, '0');
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const year = currentDate.getFullYear()

    return `${day}-${month}-${year}`
}

export function createMarkup(content) {
    const sanitizedContent = content?.replace(/<p>&nbsp;<\/p>/g, "");
    return { __html: sanitizedContent };
 }

 export const truncate = (sentence, noOfword) => {
    if(sentence.length < noOfword) return sentence;
    else{
        const word = sentence.split(" ")
        const truncateSentance = word.slice(0, noOfword).join(" ")
        return truncateSentance
    }
 }