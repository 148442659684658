import React from 'react'
import { Link, useParams } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import { useGalleryListQuery } from '../../redux/API/galleryApi';
import { gallerImg, imgUrl } from '../constant/constant';
import Navbar from '../components/partial/Navbar';
import Footer from '../components/partial/Footer';
const GalleryDetails = () => {
const {id} = useParams()  
const {data} = useGalleryListQuery(id)

  return (
    <>
    <Navbar/>
    <div className="py-5 contact-header">
        <div className="  ps-5 text-white  ">
          <div>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link> <MdKeyboardArrowRight />
                </li>
                <li className="breadcrumb-item text-white " aria-current="page">
                  Gallery <MdKeyboardArrowRight />
                </li>
                <li className="breadcrumb-item text-white " aria-current="page">
                Diwali Celebration
                </li>
              </ol>
            </nav>
          </div>
          <div>
            <h1>Diwali Celebration</h1>
          </div>
        </div>
      </div>
    <div className="container my-5">
    <div class="masonry">
      {
        data?.data?.map((i) => (
  <div class="mItem">
    <img src={`${imgUrl}${gallerImg}${i?.galleryimage}`} alt="" className='w-100 h-100'/>
  </div>
        ))
      }
  
</div>
    </div>
    <Footer/>
    </>
  )
}

export default GalleryDetails