import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../Constant/Matcher";

export const registerApi = createApi({
  reducerPath: "registerApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    mode: "cors",
  }),
  endpoints: (builder) => ({
    getRegisterOtp: builder.mutation({
      query: (postData) => ({
        url: `otp`,
        method: "POST",
        body: postData,
      }),
    }), 
    registerationSubmit: builder.mutation({
      query: (formData) => ({
        url: `registration`,
        method: "POST",
        body: formData,
      }),
    }), 
    loginSubmit: builder.mutation({
      query: (data) => ({
        url: `login`,
        method: "POST",
        body: data,
      }),
    }), 
  }),
});

export const { useGetRegisterOtpMutation, useRegisterationSubmitMutation, useLoginSubmitMutation } = registerApi;
