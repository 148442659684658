import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { Api_url } from '../../front/constant/constant'


export const blogApi = createApi({
    reducerPath: "blogApi",
    baseQuery: fetchBaseQuery({baseUrl: `${Api_url}`}),
    endpoints: (builder) => ({
        allBlog: builder.query({
            query: () => ({
             url: "blog",
             method: "GET",
            })
         }),
         blogDetails: builder.query({
            query: (id) => ({
             url: `blogdetail?blogid=${id}`,
             method: "GET",
            })
         }),
         
    })
})

export const { useAllBlogQuery, useBlogDetailsQuery } = blogApi