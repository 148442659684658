import { ExitToApp} from '@mui/icons-material';
import './navbar.css'
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import { useContext } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { SidebarContext } from '../../../context/SidebarContext';
function TextLinkExample(props) {
  const { isSiderbaropen } = useContext(SidebarContext) 
  const { toggleSidebar } = useContext(SidebarContext)
  
  return (
    <div className={!isSiderbaropen ? "NavBar" : "NavBar Navbar-change"}>
      <div className='CompanyLogo'>
        <img src={require("../photos/Brighter-Mind-logo.png")} alt='logo' />
      </div>
      <div className='MessageLogOutOption'>
        {
          isSiderbaropen && (
      <div className='Message m-2 menu-toggle'>
      <i class='bx bx-menu' id="btn" onClick={() => toggleSidebar()}><MenuIcon /></i>
      </div>
          )
        }
        <div className=' m-2'>
         <button  className='border-0' style={{
                        backgroundColor: "#9DC913",
                        padding: "5px 10px",
                        borderRadius: "4px",
                        color: "white",
                      }}>Link to Demat Account</button>
        </div>
        <div className='Message m-2'>
          <CircleNotificationsIcon />
        </div>
        <div className='LogOut mx-2' onClick={() => props.setShowLogout(true)}>
          <ExitToApp />
        </div>
      </div>
    </div>
  );
}

export default TextLinkExample;