import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: localStorage.getItem("userData") ? true : false,
  clientCode: null,
}

export const myreducers = createSlice({
    name: "myreducers",
    initialState: initialState,
    reducers: {
        loginSuccess: (state) => {
            state.isAuthenticated = true;
            localStorage.setItem('lastActivity', new Date().getTime());
        },
        setClientCode: (state, action) => {
            state.clientCode = action.payload;
          },
        logout: (state) => {
            state.isAuthenticated = false
            localStorage.removeItem("userData")
           localStorage.removeItem("clientCode")
           localStorage.removeItem('lastActivity');
        }
    },
})

export const { loginSuccess, logout, setClientCode } = myreducers.actions