import { configureStore } from "@reduxjs/toolkit";
import { myreducers } from "../reducer/reducer";
import { registerApi } from "../API/registerApi";
import { loginApi } from "../API/loginApi";
import { portfolioApi } from "../API/portfolioApi";
import { profileUploadApi } from "../API/profileuploadApi";
import { transectionuplodApi } from "../API/transectionupload";
import { pageApi } from "../API/pageApi";
import { blogApi } from "../API/blogApi";
import { galleryApi } from "../API/galleryApi";
import { dashboardApi } from "../API/dashboardApi";
import { billApi } from "../API/billApi";
import { referApi } from "../API/referApi";
import { supportApi } from "../API/supportApi";

export const store = configureStore({
    reducer: {
        myreducer: myreducers.reducer,
        [registerApi.reducerPath]: registerApi.reducer,
        [loginApi.reducerPath]: loginApi.reducer,
        [portfolioApi.reducerPath]: portfolioApi.reducer,
        [profileUploadApi.reducerPath]: profileUploadApi.reducer,
        [transectionuplodApi.reducerPath]: transectionuplodApi.reducer,
        [pageApi.reducerPath]: pageApi.reducer,
        [blogApi.reducerPath]: blogApi.reducer,
        [galleryApi.reducerPath]: galleryApi.reducer,
        [dashboardApi.reducerPath]: dashboardApi.reducer,
        [billApi.reducerPath]: billApi.reducer,
        [referApi.reducerPath]: referApi.reducer,
        [supportApi.reducerPath]: supportApi.reducer,
    },

    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([registerApi.middleware],[loginApi.middleware],[portfolioApi.middleware],[profileUploadApi.middleware], [transectionuplodApi.middleware], [pageApi.middleware], [blogApi.middleware], [galleryApi.middleware], [dashboardApi.middleware], [billApi.middleware],[referApi.middleware], [supportApi.middleware])
})