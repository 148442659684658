import React from 'react'
import Navbar from '../../components/partial/Navbar'
import Footer from '../../components/partial/Footer'
import img1 from '../../images/Manoj.jpg'
import { useAllBlogQuery } from '../../../redux/API/blogApi'
import { Link } from 'react-router-dom'
import { formatDate, truncate } from '../../constant/utils'
import { MdOutlineWatchLater } from "react-icons/md";
import { FaPlus } from "react-icons/fa6";

const TeamTwo = () => {
    const {data} = useAllBlogQuery()  

    function createMarkup2(content) {
        const sanitizedContent = content?.replace(/<p>&nbsp;<\/p>*>/g, "");
        return { __html: sanitizedContent };
     }


  return (
    <>
    <Navbar/>
    <section className='mt-3 py-5 border border-t-2 border-b-2 '>
    <div className="container">
    <h4 className='mb-0'>Mr. Manoj Bandre</h4>
    <p className='fw-semibold text-black-50'>Founder & CEO</p>
        <div className="row gy-2 team-detail">
            <div className="col-lg-4">
              
                <img src={img1} alt="CA Rajeev Ranjan" className="object-fit-contain" width={300}/>
            </div>
            <div className="col-lg-8 team-detail">
                <p>
                Manoj Bandre B.Tech, PGDFA is the founder; CEO of Brighter Mind Equity Advisor Pvt. Ltd. Manoj has previously worked with BLB Ltd. in the Proprietary Fund Management division; he played a very important role in equity research and helped in equity fund management of proprietary desk of company.
                </p>
                <p >
                Manoj has been a Dhandho mind set of investor with a keen eye on Maximization of wealth to each and every investors with greater margin of safety.
                </p>
                <p>
                Been tracking India equity market for almost decade.  His understanding about value investment in equity market and ability to keep him completely away for the market noises make him exceptionally successful fund manager. His passion and ambition to make
                </p>
                <p>
                Brighter Mind one of India value creating Investment advisor to deliver superior return to each and every investor is adding value to wealth creation of every single investor.
                </p>
                <p className='mb-1'>
                Manoj Strongly believes in some of the essential factors of achieving long term superior return,
                </p>
                <p className='mb-1'>1. Independent Business Mind Set of Thinking (Dhandho Investor)</p>
                <p className='mb-1'>2. Management Ethical Corporate Aspirations</p>
                <p className='mb-1'>3. Consistent Quality Earning Of Business</p>
                <p className='mb-1'>4. Patience and High Conviction</p>
                <p className='mb-1'>5. Timing (Valuation) & Broad Market Potential Of Business</p>
            </div>
        </div>
    </div>
    </section>
    <section className='my-5'>
        <div className="container">
            <h3>Blog</h3>
            <div className="row mt-3 gy-3">
            {
              data && data?.data?.slice(0, 2).map((i) => (  
        <div className="col-md-6" key={i?._id}>
            <div className="card  ">
             <Link to={i?._id} className=" position-relative latest-update">
             <div className="overlay-shadow position-absolute  w-100  h-100  start-0  top-0  align-items-center  justify-content-center  text-white  fs-2">
             <FaPlus />
             </div>
             </Link>
              <div className="card-body py-3 blog-card-body" style={{height: "200px"}}>
               
                <h5 className="card-title mb-3">
                 {truncate(i?.title, 5)}
                </h5>
               <p className='team-blog' dangerouslySetInnerHTML={createMarkup2(truncate(i?.description, 38))}></p>
                <span><MdOutlineWatchLater />{formatDate(i?.createdAt)}</span>
                <Link to={`/blog/${i?._id}`} className='read-more-btn float-end py-1 px-2 rounded-2'>Read More</Link>
              </div>
            </div>
          </div>
              
              ))
            }
            </div>
        </div>
    </section>
    <Footer/>
    </>
  )
}

export default TeamTwo