import React from 'react'
import '../NavBar/navbar.css'
const Footer = () => {
const getData = new Date()
const year = getData.getFullYear()

  return (
    <>
    
    <footer className='mt-5'>
        <div className='text-center h-100 d-flex  align-items-center justify-content-center '>
            <p className='mb-0'>All Right Reserved &copy;{year}, By Brighter Mind</p>
        </div>
    </footer>
    
    </>
  )
}

export default Footer