import React, { useEffect } from "react";
import "swiper/css/navigation";
import "swiper/css";
import Navbar from "../components/partial/Navbar";
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import img4 from "../images/Group 57.png";
import star from "../images/Star 46.png";
import star2 from "../images/Star 46 (1).png";
import Footer from "../components/partial/Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import arrow1 from "../images/leftarrow.png";
import arrow2 from "../images/rightarrow.png";
import img5 from "../images/BM-Letters-for-Doctors.png";
import img6 from "../images/Group 85.png";
import img7 from "../images/banner4.png";
import img8 from '../images/banner3.png'
import { Helmet } from "react-helmet-async";
const Portfolio = () => {
  useEffect(() => {
    AOS.init({
      delay: 100, // values from 0 to 3000, with step 50ms
      duration: 600, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false,
    });
  }, []);

  return (
    <>
    <Helmet>
        <title>SEBI Registered Equity Investment Advisor - Brighter Mind</title>
       
      </Helmet>
      <Navbar />
      <section className="portfolio-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 text-white">
              <div className="mt-5">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link> <MdKeyboardArrowRight />
                    </li>
                    <li
                      className="breadcrumb-item text-white "
                      aria-current="page"
                    >
                      PORTFOLIO OVERVIEW
                    </li>
                  </ol>
                </nav>
              </div>
             <div data-aos="fade-up">
             <h2 className="fw-bold mt-5 pt-lg-5">PORTFOLIO OVERVIEW</h2>
              <p>
                Our investment philosophy revolves around creating wealth
                through compounding by investing for long term. We say NO to
                Trading and F&O
              </p>
             </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-5">
        <div className="container">
          <div className="row gy-2">
            <div className="col-lg-5">
              <h6 className="orange-bg fs-5 text-center text-white py-1" >
                Introducing Wealth Compounder Fund
              </h6>
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-lg-7">
                  <h5 className=" position-relative major" data-aos="fade-up">
                    Wealth Compounder Fund consists of two major parts:
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <p data-aos="fade-up">
                Businesses with dominant industry position with quality
                management consisting of:
              </p>
            </div>
            <div className="col-12 mb-4">
              <div className="row ">
                <di className="col-lg-6">
                  <p className="purple" data-aos="fade-up">
                    Business that have potential to grow from small cap to mid
                    cap and large caps and are going through:
                  </p>
                </di>
              </div>
            </div>

            <div className="col-lg-4 overflow-hidden">
              <ul className="ps-0 consists" data-aos="fade-right">
                <li className="mb-2 d-flex align-items-baseline">
                  <img src={star} alt="Star Icon" className="me-2" width={10}/>
                  <span className=" d-inline-block">
                    {" "}
                    Rapidly scaling franchise with high barrier to entry
                  </span>
                </li>
                <li className="mb-2 d-flex align-items-baseline">
                  <img src={star} alt="Star Icon" className="me-2" width={10}/>
                  <span className=" d-inline-block">
                    Consistent Earnings, ROCE & Profitability
                  </span>
                </li>
                <li className="mb-2 d-flex align-items-baseline">
                  <img src={star} alt="Star Icon" className="me-2" width={10}/>
                  <span className=" d-inline-block">
                    Superior Cash Flow Generation
                  </span>
                </li>
                <li className="mb-2 d-flex align-items-baseline">
                  <img src={star} alt="Star Icon" className="me-2" width={10}/>
                  <span className=" d-inline-block">
                    Sound and healthy balance sheet
                  </span>
                </li>
                <li className="mb-2 d-flex align-items-baseline">
                  <img src={star} alt="Star Icon" className="me-2" width={10}/>
                  <span className=" d-inline-block">
                    Pricing Power with superior MOAT
                  </span>
                </li>
                <li className="mb-2 d-flex align-items-baseline">
                  <img src={star} alt="Star Icon" className="me-2" width={10}/>
                  <span className=" d-inline-block">
                    {" "}
                    Rapidly scaling franchise with high barrier Prudent capital
                    allocation
                  </span>
                </li>
                <li className="mb-2 d-flex align-items-baseline">
                  <img src={star} alt="Star Icon" className="me-2" width={10}/>
                  <span className=" d-inline-block">
                    Superior accounting and governance quality
                  </span>
                </li>
              </ul>
            </div>
            <div className="col-lg-4 text-center pt-5 " data-aos="fade-up">
              <div className="position-relative h-100 graph-box">
              <span className=" position-absolute start-0 " style={{color: "rgba(215, 56, 122, 1)"}}>Consistent Large-Caps: 40%</span>
              <span className="purple position-absolute end-0 " >Emerging Small-Mid-Caps: 60%</span>
                <img
                  src={arrow1}
                  alt="Graph"
                  className="object-fit-contain position-absolute top-0  start-0"
                />
                <img
                  src={arrow2}
                  alt="Graph"
                  className="object-fit-contain position-absolute top-0 end-0"
                />
                <img
                  src={img4}
                  alt=""
                  className=" h-100 object-fit-contain position-relative z-2"
                  width={260}
                />
              
              </div>
            </div>
            <div className="col-lg-4 overflow-hidden">
              <ul className="ps-0 consists " data-aos="fade-left">
                <li className="mb-2 d-flex align-items-baseline">
                  <img src={star2} alt="Star Icon" className="me-2" width={10}/>
                  <span className=" d-inline-block">
                    Mean reversion and temporary distress
                  </span>
                </li>
                <li className="mb-2 d-flex align-items-baseline">
                  <img src={star2} alt="Star Icon" className="me-2" width={10}/>{" "}
                  <span className=" d-inline-block">
                    Disciplined in capital allocation and working capital
                  </span>
                </li>
                <li className="mb-2 d-flex align-items-baseline">
                  <img src={star2} alt="Star Icon" className="me-2" width={10}/>
                  <span className=" d-inline-block">
                    Rapidly scaling franchise with high barrier to entry
                  </span>
                </li>
                <li className="mb-2 d-flex align-items-baseline">
                  <img src={star2} alt="Star Icon" className="me-2" width={10}/>
                  <span className=" d-inline-block">
                    Focusing on higher margin & capital efficiency
                  </span>
                </li>
                <li className="mb-2 d-flex align-items-baseline">
                  <img src={star2} alt="Star Icon" className="me-2" width={10}/>
                  <span className=" d-inline-block">
                    Change in Management & Demerger
                  </span>
                </li>
                <li className="mb-2 d-flex align-items-baseline">
                  <img src={star2} alt="Star Icon" className="me-2" width={10} />
                  <span className=" d-inline-block">
                    Sectoral tail-wind through economical and government
                    policies
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-5 portfolio_structure_section py-3">
        <div className="container">
          <h3 className="text-center">Brighter Mind Portfolio Structure</h3>
          <div className="row gy-3 mt-3">
            <div className="col-lg-6 text-center position-relative">
              <span className="text-danger fs-5 fw-bold">35-45</span>
              <h5> Number of Stocks</h5>
              <h6 className="fw-semibold text-danger fs-6 mt-5 pt-3">
                Large Cap 40%
              </h6>
              <span className="fw-semibold text-danger fs-6 mt-5 pt-3 position-absolute graph-text">
                Large Cap <br /> 40%
              </span>
              <img
                src={img6}
                alt="Stocks Consist of 20% Position Size"
                className="mt-2"
                width={300}
                data-aos="flip-left"
              />
              <span className="fw-semibold text-danger fs-6 mt-5 pt-3 position-absolute graph-text-two">
                Small Cap <br /> 20%
              </span>
            </div>
            <div className="col-lg-6 text-center">
              <span className="text-danger fs-5 fw-bold"> Top 5</span>
              <h5>
                Stocks Consist of 20% <br /> Position Size
              </h5>
              <img
                src={img5}
                alt="Stocks Consist of 20% Position Size"
                className="mt-3 w-100  object-fit-contain"
               height={280}
                data-aos="flip-right"
              />
            </div>
          </div>
        </div>
      </section>
     
      <section className="mt-5 ">
        <div className="container">
          <div className="row gy-3  py-4 stock-overview-section">
            <div className="col-12">
              <h3 className="text-center">Our Stock Research Framework</h3>
            </div>
            <di className="col-12 h-100">
              <img src={img7} alt="Our Stock Research Framework" data-aos="zoom-in-down"/>
              
            </di>
          </div>
        </div>
      </section>
      <section className="container mt-5">
        <h3 className="text-center">Our Stock Selection Process - Deep Dive</h3>
        <div className="row mt-4">
          <div className="col-12 mx-auto stock-selection-section text-center" data-aos="fade-down">
          <img
                src={img8}
                alt="Our Stock Selection Process - Deep Dive"
               className="mx-auto"

              />
          </div>
        </div>
      </section>
      <section className="container mt-5">
        <div className="row">
          <div className="col-12 ">
          <div className="table-responsive">
              <table className="table table-bordered border-black">
                <thead>
                  <tr>
                    <th  colspan="6" className="contact-header text-white text-center">
                      SEBI Registration Details
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Registered Name</td>
                    <td>BRIGHTER MIND EQUITY ADVISOR PRIVATE LIMITED</td>
                    
                  </tr>
                  <tr>
                    <td>Type of Registration</td>
                    <td>Non-Individual Investment Advisor</td>
                    
                  </tr>
                  <tr>
                    <td>Registration No.</td>
                    <td> INA100016363</td>
                    
                  </tr>
                  <tr>
                    <td >Validity</td>
                    <td >November 08, 2021 Perpetual</td>
                    
                  </tr>
                  <tr>
                    <td>SEBI Regional Office Address</td>
                    <td>NBCC Complex, Office Tower-1, 8th Floor, Plate B, East Kidwai Nagar, New Delhi - 110023</td>
                    
                  </tr>
                  <tr>
                    <td></td>
                    <td>Email ID(Principal Officer):shashi.brightermind@gmail.in <br />
                    Telephone(Principal Officer): 011 43039913
                    </td>
                    
                  </tr>
                </tbody>
              </table>
            </div>
           
            <div className="text-center">
            <Link
              to="https://drive.google.com/file/d/1QXQ4VVYp0vugWksUlObhAiutcTOQx1Fr/view"
              className="mt-4 py-2 px-3 rounded-1 border-0 text-white sebi-btn "
              data-aos="fade-left"
            >
              View SEBI Registration Certificate
            </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-5 protfolio-bottom py-5">
        <div className="container">
          <div className="row gy-3">
            <div className="col-lg-6">
              <h5 className="mb-0 text-white">
                Want to know Brighter Mind Investment Philosophy?
              </h5>
            </div>
            <div className="col-lg-6 text-lg-end">
              <Link to="/contact" className="lets-connect py-2 px-4">
                Lets Connect <MdKeyboardArrowRight className="fs-5" />
              </Link>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Portfolio;
