import React, { useContext, useEffect, useState } from "react";
import SideBar from "../Components/SideBar/SideBar";
import TextLinkExample from "../Components/NavBar/NavBar";
import { SidebarContext } from "../../context/SidebarContext";
import { Feedback } from "./Feedback";
import Footer from "../Components/Footer/Footer";
import LogoutCard from "../Components/Card/LogoutCard";
import "../Pages/Style/profile.css";
import { FaUpload } from "react-icons/fa";
import { MdOutlineCancel } from "react-icons/md";
import { useProfileInfoQuery } from "../../redux/API/portfolioApi";
import { useUploadImgMutation } from "../../redux/API/profileuploadApi";
import { imageUrl } from "../../Constant/Matcher";
import { connect } from "react-redux";
import { setClientCode } from "../../redux/reducer/reducer";
import placeholderimg from '../Components/photos/png-transparent-computer-icons-user-user-icon-thumbnail.png'
const Profile = () => {
  const { isSiderbaropen } = useContext(SidebarContext);
  const [showLogout, setShowLogout] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [profileimg, setProfileImg] = useState("");
  const [docimageUrl, setDocImageUrl] = useState("");
  const [profileData, setProfileData] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [error, setError] = useState("");
  const getProfile = useProfileInfoQuery();
  const [imageUpload, {isLoading}] = useUploadImgMutation();
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setProfileImg(file);
    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (getProfile.isSuccess && !getProfile.isError && !getProfile.isLoading) {
      setProfileData(getProfile?.data?.user);
      localStorage.setItem("clientCode", getProfile?.data?.user?.clientcode)
    } else {
      // console.log("Something went wrong");
    }
  }, [getProfile]);

  const handleImageUpload = (e) => {
    e.preventDefault();
    if (profileimg === "") {
      setError("Image not Selected")
      setTimeout(() => {
        setError("")
      }, 3000);
      return;
    }
    const postData = new FormData();
    postData.append("profileimg", profileimg);

    imageUpload(postData)
      .then(() => {
       setProfileImg("")
      })
      .catch((error) => {
        alert(error);
      });
  };

  const styles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000,
    },
    modal: {
      position: 'relative',
      background: '#fff',
      padding: '20px',
      borderRadius: '10px',
      maxWidth: '80%',
      height: '350px',
      boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
    },
    
    closeButton: {
      position: 'absolute',
      top: '10px',
      right: '10px',
      background: 'none',
      border: 'none',
      cursor: 'pointer',
    },
  };
  

  const handleView = (imgUrl) => {
    setDocImageUrl(imgUrl)
    setShowModal(true)
  }

  return (
    <>
      <div className="App">
        <SideBar />
        <div className={!isSiderbaropen ? "pages" : "pages-change"}>
          <TextLinkExample setShowLogout={setShowLogout} />
          <div className="HomeCard ">
            <form
              className="card p-3 col-11  m-auto d-flex  flex-row  "
              action="POST"
              onSubmit={handleImageUpload}
            >
              <div className="d-flex align-items-center  justify-content-start   w-50 ">
                <div className="profile-img">
                  <img
                    className="w-100 h-100 overflow-hidden object-fit-cover   "
                    src={
                       selectedImage || (profileData?.profileimg ? `${imageUrl}${profileData?.profileimg}`
                      : 
                      placeholderimg)
                    }
                    alt="profile-img"
                    
                  />
                </div>
                <div className=" d-flex  flex-column  w-75  h-100 justify-content-center ">
                  <div className="position-relative d-flex  align-items-center  justify-content-center  mt-4 ">
                    <input
                      type="file"
                      name="profileimg"
                      id="profileimg"
                      className="d-none"
                      onChange={handleImageChange}
                    />
                    <label
                      htmlFor="profileimg"
                      className="upload-profile position-absolute  "
                    >
                      <FaUpload /> Upload a New Photo
                    </label>
                  </div>

                  <div className="mt-3 text-center ">
                    <span>
                      {profileimg ? profileimg.name : "No file selected"}
                    </span>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column justify-content-end align-items-end  w-50  ">
                <button type="submit" className="px-2 py-1 update-btn">
                  {
                    isLoading ? (
                      "Uploading..."
                    )
                    : 
                    (
                      "Update"
                    )
                  }
                </button>
                <span className="text-danger">{error}</span>
              </div>
            </form>

            <div className="card p-3 col-11  m-auto mt-3  ">
              <h4>Personal Information</h4>
              <form className="row g-3">
                <div className="col-md-6">
                  <label htmlFor="name" className="form-label fw-semibold">
                    Name
                  </label>
                 
                    <input
                      readOnly
                      type="text"
                      className="form-control"
                      id="name"
                      value={profileData?.userid?.name}
                    />
                
                </div>
                <div className="col-md-6">
                  <label htmlFor="inputEmail4" className="form-label fw-semibold">
                    Email
                  </label>
                  
                    <input
                      type="email"
                      className="form-control"
                      id="inputEmail4"
                      value={profileData?.userid?.email}
                    />
                 
                </div>
                <div className="col-md-6">
                  <label htmlFor="inputPassword4" className="form-label fw-semibold">
                    Mobile No.
                  </label>
                 
                    <input
                      type="text"
                      maxLength={10}
                      className="form-control"
                      id="inputPassword4"
                      value={profileData?.userid?.phone}
                    />
                 
                </div>
                <div className="col-md-6">
                  <label htmlFor="inputPassword4" className="form-label fw-semibold">
                    Investement Amount
                  </label>
                 
                    <input
                      type="text"
                      maxLength={10}
                      className="form-control"
                      id="inputPassword4"
                      value={profileData?.investmentamount}
                    />
                  
                </div>
                <div className="col-12">
                  <label htmlFor="inputAddress" className="form-label fw-semibold">
                    Address
                  </label>
                  
                    <input
                      type="text"
                      className="form-control"
                      id="inputAddress"
                      value={profileData?.address}
                    />
                 
                </div>
                <div className="col-12">
                  <label htmlFor="inputAddress" className="form-label fw-semibold">
                    Permanent Address
                  </label>
                 
                    <input
                      type="text"
                      className="form-control"
                      id="inputAddress"
                      value={profileData?.permanentAddress}
                    />
              
                </div>
               
              </form>
            </div>
            <div className="card p-3 col-11  m-auto mt-3  ">
              <h4>Documents</h4>
              <form className="row g-3">
                <div className="col-md-6">
                  <label htmlFor="name" className="form-label fw-semibold">
                    PAN Number
                  </label>
                    <div className="d-flex">
                    <input
                      type="text"
                      className="form-control w-75 me-3"
                      id="name"
                      value={profileData?.PAN}
                    />
                 <button className="text-primary text-decoration-underline border-0 bg-transparent " type="button" onClick={()=> handleView(`${imageUrl}${profileData?.panCard}`)}>View</button>
                    </div>
                </div>
                <div className="col-md-6">
                  <label htmlFor="inputEmail4" className="form-label fw-semibold">
                    Aadhar Number
                  </label>
                  <div className="d-flex">
                    <input
                      type="text"
                      className="form-control w-75 me-3"
                      id="inputEmail4"
                      value={profileData?.aadhar}
                    />
                 <button className="text-primary text-decoration-underline border-0 bg-transparent " type="button"
                 onClick={()=> handleView(`${imageUrl}${profileData?.aadharCard}`)}
                 >View</button>
                 </div>
                </div>
                <div className="col-md-6">
                  <label htmlFor="inputPassword4" className="form-label w-100 fw-semibold">
                    Cheque
                  </label>
                  
                   <img  src={`${imageUrl}${profileData?.cheque}`} alt="Cheque" className=" object-fit-contain" width={300} height={300} />
                 
                </div>
                <div className="col-md-6">
                  <label htmlFor="inputPassword4" className="form-label w-100 fw-semibold">
                    Signature
                  </label>
                   <img src={`${imageUrl}${profileData?.signature}`} alt="Signature" className="object-fit-contain" width={300}/>
                 
                </div>

              </form>
            </div>
          </div>
          <Footer />
        </div>
        <Feedback />
      </div>
      {showLogout && <LogoutCard setShowLogout={setShowLogout} />}
      {
        showModal && (
      
      <div style={styles.overlay}>
      <div style={styles.modal}>
        <img src={docimageUrl} alt="Document" className="object-fit-contain" height={300} />
        <button onClick={() => setShowModal(false)} style={styles.closeButton}>
          <MdOutlineCancel size={24} />
        </button>
      </div>
    </div>
        
      )
    }
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
 
  setClientCode: (clientCode) => dispatch(setClientCode(clientCode)),
});

export default connect(null, mapDispatchToProps)(Profile);
