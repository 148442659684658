import React from 'react'
import { Link, useParams } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import { MdOutlineWatchLater } from "react-icons/md";
import { FaSearch } from "react-icons/fa";
import { useBlogDetailsQuery } from '../../redux/API/blogApi';
import { blogImg, imgUrl } from '../constant/constant';
import { createMarkup } from '../constant/utils';
import Navbar from '../components/partial/Navbar';
import Footer from '../components/partial/Footer';
const Blogdetails = () => {
const {id} = useParams()  
const {data} = useBlogDetailsQuery(id)


  return (
    <>
    <Navbar/>
    <div className="py-5 contact-header">
        <div className="  ps-5 text-white  ">
          <div>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link> <MdKeyboardArrowRight />
                </li>
                <li className="breadcrumb-item text-white " aria-current="page">
                Blogs <MdKeyboardArrowRight />
                </li>
                <li className="breadcrumb-item text-white " aria-current="page">
                {data?.data?.title}
                </li>
              </ol>
            </nav>
          </div>
          <div>
            <h1>{data?.data?.title}</h1>
          </div>
        </div>
      </div>
      <div className="container my-5">
      <div className="row">
        <div className="col-lg-11 mx-auto">
          <div className="card mb-3">
            <img src={`${imgUrl}${blogImg}${data?.data?.image}`} alt="Blog" height={540} className=' object-fit-contain ' />
          </div>
          <p dangerouslySetInnerHTML={createMarkup(data?.data?.description)}></p>
         
        </div>
        {/* <div className="col-lg-3">
          <div className='search-box d-flex justify-content-between '>
            <input type="text" className=' bg-transparent border-0 w-100 py-2 ps-3' placeholder="Search..." />
            <button className=' border-0  contact-header text-white  px-3 py-2 '><FaSearch /></button>
          </div>
          <div className="blog-category mt-5">
            <h4 className=' fw-normal heading mb-4'>Categories</h4>
            <div>
                <div className='category-box p-3 ps-4 mb-1 position-relative '>
                  <Link><h6 className="mb-0">Stock Analysis</h6></Link>
                </div>
                <div className='category-box p-3 ps-4 mb-1 position-relative'>
                  <Link><h6 className="mb-0">Equity Investment</h6></Link>
                </div>
                <div className='category-box p-3 ps-4 mb-1 position-relative'>
                  <Link><h6 className="mb-0">Infographics</h6></Link>
                </div>
                <div className='category-box p-3 ps-4 mb-1 position-relative'>
                  <Link><h6 className="mb-0">Inflation</h6></Link>
                </div>
                <div className='category-box p-3 ps-4 mb-1 position-relative'>
                  <Link><h6 className="mb-0">Power of Compounding</h6></Link>
                </div>
                <div className='category-box p-3 ps-4 mb-1 position-relative'>
                  <Link><h6 className="mb-0">Risks in Equity Market</h6></Link>
                </div>
                <div className='category-box p-3 ps-4 mb-1 position-relative'>
                 <Link> <h6>Time Value of Money</h6></Link>
                </div>
                <div className='category-box p-3 ps-4 mb-1 position-relative'>
                  <Link><h6 className="mb-0">Uncategorized</h6></Link>
                </div>
                <div className='category-box p-3 ps-4 mb-1 position-relative'>
                  <Link><h6 className="mb-0">Value Investing</h6></Link>
                </div>
            </div>
          </div>
          <div className="latest-category mt-5">
            <h4 className=' fw-normal heading mb-4'>Latest Posts</h4>
            <div className='mb-4'>
                <p className='m-0 fw-semibold text-start '><Link>The Role of Patience in Value Investing</Link></p>
                <span className='sm-text'><MdOutlineWatchLater /> 2022-12-20</span>
            </div>
            <div className='mb-4'>
                <p className='m-0 fw-semibold text-start'><Link>The Role of Patience in Value Investing</Link></p>
                <span className='sm-text'><MdOutlineWatchLater /> 2022-12-20</span>
            </div>
            <div className='mb-4'>
                <p className='m-0 fw-semibold text-start'><Link>The Role of Patience in Value Investing</Link></p>
                <span className='sm-text'><MdOutlineWatchLater /> 2022-12-20</span>
            </div>
            <div className='mb-4'>
                <p className='m-0 fw-semibold text-start'><Link>The Role of Patience in Value Investing</Link></p>
                <span className='sm-text'><MdOutlineWatchLater /> 2022-12-20</span>
            </div>
            <div className='mb-4'>
                <p className='m-0 fw-semibold text-start'><Link>The Role of Patience in Value Investing</Link></p>
                <span className='sm-text'><MdOutlineWatchLater /> 2022-12-20</span>
            </div>
          </div>
        </div> */}
      </div>
    </div>
    <Footer/>
    </>
  )
}

export default Blogdetails