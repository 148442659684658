import React, { useEffect } from "react";
import img from "../../images/logo (1).png";
import icon1 from '../../images/icon1.svg'
import icon2 from '../../images/icon2.svg'
import icon3 from '../../images/icon3.svg'
import icon4 from '../../images/icon4.svg'
import icon5 from '../../images/icon5.svg'
import icon6 from '../../images/icon6.svg'
import icon7 from '../../images/icon7.svg'
import icon8 from '../../images/icon8.svg'
import AOS from "aos";
import "aos/dist/aos.css";
const Whyus = () => {

  useEffect(() => {
    AOS.init({
      delay: 100, // values from 0 to 3000, with step 50ms
      duration: 600, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false,
    });
  }, []);



  return (
    <>
      <div className="container mt-5">
        <h2 className="heading text-center ">Why Us</h2>
        <h3 className="heading text-center ">
          Reason for Choose <span>Brightermind</span>
        </h3>
        <div className="row gy-3 mt-3  py-3 overflow-hidden">
          <div className="col-lg-4" data-aos="fade-right">
            <div className="row gy-4">
              <div className="col-12">
                <div className="d-flex align-items-center  ">
                  <div className="para_box position-relative m-0 text-black   px-4 py-2 w-100">
                   <p className="mb-0">
                   <strong className="bg-transparent">Personalised Portfolio Design:</strong> <br /> We don’t follow
                   one-suit-fits-all solution
                   </p>
                    <span className="count-box position-absolute text-white  d-flex justify-content-center  align-items-center fs-5">
                      <img src={icon1} alt="" className="pt-2"/>
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="d-flex align-items-center  ">
                  <div className="para_box position-relative m-0  text-black   px-4 py-2 w-100">
                  <p className="mb-0">
                  <strong className="bg-transparent">Safety of Capital:</strong> <br /> Right allocation and Diversification help
                  us achieve our goal
                  </p>
                    <span className="count-box position-absolute text-white  d-flex justify-content-center  align-items-center fs-5">
                    <img src={icon3} alt="" className="pt-2"/>
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="d-flex align-items-center  ">
                  <div className="para_box position-relative m-0  text-black   px-4 py-2 w-100">
                   <p className="mb-0">
                   <strong className="bg-transparent">Regular Portfolio Reviews:</strong> <br /> In order to stay on track of
                   investment
                   </p>
                    <span className="count-box position-absolute text-white   d-flex justify-content-center  align-items-center fs-5">
                    <img src={icon5} alt="" className="pt-2"/>
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="d-flex align-items-center  ">
                  <div className="para_box position-relative m-0  text-black last_para_box1  px-4 py-2 w-100">
                   <p className="mb-0">
                   <strong className="bg-transparent">Active Portfolio Strategy:</strong> <br /> We don’t follow Buy-At-Any-Price
                   (BAAP) Approach
                   </p>
                    <span className="count-box position-absolute text-white  d-flex justify-content-center  align-items-center fs-5">
                    <img src={icon7} alt="" className="pt-2"/>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 d-flex justify-content-center align-items-center mb-0 mb-lg-5" data-aos="fade-up">
            <div className="why-us-round d-flex justify-content-center align-items-center">
              <img src={img} alt="Rupee" />
            </div>
          </div>
          <div className="col-lg-4" data-aos="fade-left">
            <div className="row gy-4">
            <div className="col-12">
              <div className="d-flex align-items-center  ">
                <div className="para_box position-relative para_box2 position-relative2 m-0  text-black   px-4 py-2 w-100">
                 <p className="mb-0">
                 <strong className="bg-transparent">Higher Alpha Generation:</strong> <br /> Why not beat Indices if investing in
                 direct equities
                 </p>
                  <span className="count-box position-absolute text-white   d-flex justify-content-center  align-items-center fs-5">
                  <img src={icon2} alt="" className="pt-2"/>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="d-flex align-items-center  ">
                <div className="para_box position-relative para_box2 position-relative2 m-0  text-black   px-4 py-2 w-100">
                 <p className="mb-0">
                 <strong className="bg-transparent">Wealth Creation in Long Term:</strong> <br /> Let compounding be your friend
                 and work for long term
                 </p>
                  <span className="count-box position-absolute text-white   d-flex justify-content-center  align-items-center fs-5">
                  <img src={icon4} alt="" className="pt-2"/>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="d-flex align-items-center  ">
                <div className="para_box position-relative para_box2 position-relative2 m-0  text-black   px-4 py-2 w-100">
                 <p className="mb-0">
                 <strong className="bg-transparent">No Model Buckets:</strong> <br /> There are no pre-determined buckets
                 </p>
                  <span className="count-box position-absolute text-white   d-flex justify-content-center  align-items-center fs-5">
                  <img src={icon6} alt="" className="pt-2"/>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="d-flex align-items-center  ">
                <div className="para_box last_para_box2 position-relative para_box2 position-relative2 m-0  text-black   px-4 py-2 w-100">
                 <p className="mb-0">
                 <strong className="bg-transparent">Exit Strategy:</strong> <br /> Some profits are meant to be booked but some
                 are to run a marathon.
                 </p>
                  <span className="count-box position-absolute text-white   d-flex justify-content-center  align-items-center fs-5">
                  <img src={icon8} alt="" className="pt-2" />
                  </span>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
     
    </>
  );
};

export default Whyus;
