import React, { useContext, useState } from 'react'
import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import "../Pages/Style/dashboard.css";
import "../../App.css";
import "../Pages/Style/billsinvoice.css";
import LogoutCard from '../Components/Card/LogoutCard';
import Footer from '../Components/Footer/Footer';
import TextLinkExample from '../Components/NavBar/NavBar';
import { SidebarContext } from '../../context/SidebarContext';
import SideBar from '../Components/SideBar/SideBar';

const FranchiseDetails = () => {
  const { isSiderbaropen } = useContext(SidebarContext);
  const [showLogout, setShowLogout] = useState(false);

  return (
    <>
    <div className="App">
        <SideBar />
        <div className={!isSiderbaropen ? "pages" : "pages-change"}>
          <TextLinkExample setShowLogout={setShowLogout} />
          <div className=" BillsInvoice  min-vh-100 ">
            <div className='d-flex justify-content-end mx-4'>
                <div className='position-relative border border-2 border-black-50 ps-2 py-1'>
                <input type="text" className='border-0' placeholder='Search...' />
                </div>
            </div>
          <div className="PortfolioReviewHeader mx-4 my-4 py-2 px-2">
            <h4>Franchise Details</h4>
          </div>
          <div className="BillsInvoiceTable mx-4 overflow-y-hidden ">
              <MDBTable
                hover
                striped
                style={{
                  backgroundColor: "RGB(255 255 255)",
                  fontSize: "12px",
                }}
              >
                <MDBTableHead
                  style={{ backgroundColor: "#5A4344", color: "white" }}
                >
                  <tr>
                    <th scope="col">CP Name</th>
                    <th scope="col">Mobile No.</th>
                    <th scope="col">Email</th>
                    <th scope="col">Total Clients</th>
                    <th scope="col">Total Investment</th>
                    <th scope="col">Total AUA</th>
                    <th scope="col">Revenue Share</th>
                    <th scope="col">View Detail</th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  
                      <tr >
                        <td>John Doe</td>
                        <td>+91 909909009</td>
                        <td>test@gmail.com</td>
                        <td>25</td>
                        <td>200000</td>
                        <td>10000</td>
                        <td>20%</td>
                        <td>View</td>
                      </tr>
                      <tr >
                        <td>John Doe</td>
                        <td>+91 909909009</td>
                        <td>test@gmail.com</td>
                        <td>25</td>
                        <td>200000</td>
                        <td>10000</td>
                        <td>20%</td>
                        <td>View</td>
                      </tr>
                      <tr >
                        <td>John Doe</td>
                        <td>+91 909909009</td>
                        <td>test@gmail.com</td>
                        <td>25</td>
                        <td>200000</td>
                        <td>10000</td>
                        <td>20%</td>
                        <td>View</td>
                      </tr>
                      <tr >
                        <td>John Doe</td>
                        <td>+91 909909009</td>
                        <td>test@gmail.com</td>
                        <td>25</td>
                        <td>200000</td>
                        <td>10000</td>
                        <td>20%</td>
                        <td>View</td>
                      </tr>
                     
                  
                </MDBTableBody>
              </MDBTable>
            </div>
          </div>
          <Footer/>
          </div>
          </div>
          {showLogout && <LogoutCard setShowLogout={setShowLogout} />}
    </>
  )
}

export default FranchiseDetails