import React from 'react'
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import { MdOutlineWatchLater } from "react-icons/md";
import { FaPlus } from "react-icons/fa6";
import { FaSearch } from "react-icons/fa";
import { useAllBlogQuery } from '../../redux/API/blogApi';
import { formatDate, truncate } from '../constant/utils';
import { blogImg, imgUrl } from '../constant/constant';
import Skeletoncard from '../components/partial/Skeletoncard';
import { LazyLoadImage } from 'react-lazy-load-image-component'
import img1 from '../images/placeholder.jpg'
import Navbar from '../components/partial/Navbar';
import Footer from '../components/partial/Footer';
import { Helmet } from 'react-helmet-async';
const Blog = () => {

const {data, isLoading} = useAllBlogQuery()  


  return (
    <>
     <Helmet>
        <title>Equity Marketing related Important Blogs - Share Market | Brighter Mind</title>
        <meta name="description" content="Latest and Trending blogs on Equity market, also get an insight on the share market, and also we provide the basic knowledge about share market through blogs. Read Blogs" />
      </Helmet>
    <Navbar/>
     <div className="py-5 contact-header">
        <div className="  ps-5 text-white  ">
          <div>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link> <MdKeyboardArrowRight />
                </li>
                <li className="breadcrumb-item text-white " aria-current="page">
                Blogs
                </li>
              </ol>
            </nav>
          </div>
          <div>
            <h1>Blogs</h1>
          </div>
        </div>
      </div>
    <div className="container my-5">
      <div className="row">
        <div className="col-lg-12">
          {
            isLoading ? (
              <Skeletoncard/>
            )
            : 
            (   
          <div className="row gy-3">
            {
              data && data?.data?.map((i) => (  
        <div className="col-lg-3 col-md-6" key={i?._id}>
             <Link to={i?._id} className=" position-relative latest-update">
            <div className="card  ">
             <div className="position-relative latest-update">
             <LazyLoadImage
            className='w-100'
            src={`${imgUrl}${blogImg}${i?.image}`}
            placeholderSrc={img1}
            alt={""}
            height={200}
          />
             <div className="overlay-shadow position-absolute  w-100  h-100  start-0  top-0  align-items-center  justify-content-center  text-white  fs-2">
             <FaPlus />
             </div>
             </div>
           
              <div className="card-body py-3 blog-card-body ">
                <div className="border-line mb-3"></div>
                <h5 className="card-title mb-3">
                 {truncate(i?.title, 4)}
                </h5>
               
                <span><MdOutlineWatchLater />{formatDate(i?.createdAt)}</span>
              </div>
            </div>
            </Link>
          </div>
               
              ))
            }
          </div>
            )
          }
        </div>
       
      </div>
    </div>
    <Footer/>
    </>
  )
}

export default Blog