import React, { useState } from "react";
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import { IoIosArrowForward } from "react-icons/io";
import Navbar from "../components/partial/Navbar";
import Footer from "../components/partial/Footer";
import { Helmet } from "react-helmet-async";
const WealthCalculator = () => {
  const [showLabel, setshowLabel] = useState(false);
  const [showForm, setshowForm] = useState(false);
  const [showSecondForm, setshowSecondForm] = useState(false);

const handleFormShow = () => {
  setshowSecondForm(true)
  setshowForm(false)
}

const handleForm = () => {
  setshowForm(true)
  setshowSecondForm(false)
}

  return (
    <>
     <Helmet>
        <title>Wealth Calculator - how to become crorepati | Brighter Mind</title>
        <meta name="description" content="Wealth Calculator - Calculate the amount you should invest to achieve your desired wealth over a period of time. Get complete action plan for completing your wealth goal." />
      </Helmet>
    <Navbar/>
      <div className="py-5 contact-header">
        <div className="  ps-5 text-white  ">
          <div>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link> <MdKeyboardArrowRight />
                </li>
                <li className="breadcrumb-item text-white " aria-current="page">
                  Wealth Calculator
                </li>
              </ol>
            </nav>
          </div>
          <div>
            <h1>Wealth Calculator</h1>
          </div>
        </div>
      </div>
      <div className="container my-4 wealth-calculator">
        <div className="row justify-content-center ">
          <h2 className=" text-center  heading">BECOME A CROREPATI</h2>
          <div className="col-lg-7">
            <form action="" className="w-100 p-4 mt-2">
              <div className="mb-3">
                <label htmlFor="" className=" text-dark-emphasis ">
                  How many Crores You want to Create?
                </label>
                <input type="text" className="w-100 p-1" />
              </div>
              <div className="mb-3">
                <label htmlFor="" className=" text-dark-emphasis ">
                  In how many years?
                </label>
                <input type="range" className="w-100 p-1" />
              </div>
              <div className="mb-3">
                <label className="   text-dark-emphasis ">
                  *Below calculation is based on the CAGR of 20%
                </label>
              </div>
              <div className="mb-3">
                <label className="   text-dark-emphasis ">
                  You should start your investment with
                </label>
                <h4 className=" text-dark-emphasis ">₹21,83,874</h4>
              </div>
              <div className="action-plan d-flex flex-column   p-4">
                <h5 className="text-center text-dark-emphasis mb-4">
                  <Link onClick={() => setshowLabel(true)}>
                    SEE ACTION PLAN
                  </Link>
                </h5>
                {showLabel && (
                  <div className="d-flex  flex-column ">
                    <label
                      htmlFor=""
                      className=" text-dark-emphasis "
                      onClick={handleForm}
                    >
                      <input
                        type="radio"
                        name="invest"
                        id="invest"
                        className="me-2"
                      />
                      I can invest this amount
                    </label>
                    <label
                      htmlFor=""
                      className=" text-dark-emphasis "
                      onClick={handleFormShow}
                    >
                      <input
                        type="radio"
                        name="invest"
                        id="invest"
                        className="me-2"
                      />
                      I don't have the required amount
                    </label>
                  </div>
                )}
                {showForm && (
                  <div className="mt-3 form-box ">
                    <div className="mb-3">
                      <input
                        type="text"
                        className="w-100 p-1"
                        placeholder="First name"
                      />
                    </div>
                    <div className="mb-3">
                      <input
                        type="text"
                        className="w-100 p-1"
                        placeholder="Phone Number"
                      />
                    </div>
                    <div className="mb-4">
                      <input
                        type="text"
                        className="w-100 p-1"
                        placeholder="Your Email Address"
                      />
                    </div>
                    <div className="mb-3">
                      <button className=" border-0 py-2 px-4 rounded-1 fw-semibold ">
                        Submit
                        <IoIosArrowForward />
                      </button>
                    </div>
                    <div className="divider-box my-4"></div>
                  </div>
                )}
                {showSecondForm && (
                  <div className="second-form">
                    <div className="mb-3">
                      <label htmlFor="" className=" text-dark-emphasis fs-5">
                        How much amount you can invest today
                      </label>
                      <input type="text" className="w-100 p-1" />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="" className=" text-dark-emphasis fs-5">
                        How many years you want to add funds
                      </label>
                      <input type="text" className="w-100 p-1" />
                    </div>
                    <div className="mb-3">
                      <h5 className=" fw-normal ">
                        You should invest every year
                      </h5>
                      <h4>₹3,86,562</h4>
                    </div>
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default WealthCalculator;
