import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { baseUrl } from "../../Constant/Matcher";



export const referApi = createApi({
    reducerPath: "referApi",
    baseQuery: fetchBaseQuery({baseUrl: `${baseUrl}`}),
    endpoints: (builder) => ({
        referUs: builder.mutation({
            query: (formData) => ({
              url: `front/referus`,
              method: "POST",
              body: formData,
            }),
          }), 
        getReferData: builder.query({
            query: (userId) => ({
             url: `front/referus?userid=${userId}`,
             method: "GET",
            })
         }),
         
    })
})

export const { useReferUsMutation, useGetReferDataQuery } = referApi