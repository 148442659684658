import React from "react";
import img from '../../images/relaximage (1).svg'
const Strategy = () => {
  return (
    <>
      <div className="container mt-5 ">
        <h2 className="heading text-center ">
         Strategy
        </h2>
        <p className=" m-auto text-center w-75">
          Growing Business with skilled management, robust corporate governance,
          and diversified investments across market caps suits our way of
          investing!
        </p>
        <div className="row w-100  pb-3 justify-content-center">
         <div className="col-12 strategy-banner my-4">
         <img src={img} alt="" className="w-100 h-100 object-fit-contain " />
         </div>
        </div>
      </div>
     
    </>
  );
};

export default Strategy;
