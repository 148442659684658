import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { baseUrl } from "../../Constant/Matcher";



export const dashboardApi = createApi({
    reducerPath: "dashboardApi",
    baseQuery: fetchBaseQuery({baseUrl: `${baseUrl}`}),
    endpoints: (builder) => ({
        getData: builder.query({
            query: (id) => ({
             url: `dashboard?cid=${id}`,
             method: "GET",
            })
         }),
         
    })
})

export const { useGetDataQuery } = dashboardApi