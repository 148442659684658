import React from 'react'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay, Pagination, Navigation, FreeMode } from 'swiper/modules';
import { useTestmonialQuery } from '../../../redux/API/pageApi';
const Testimonial = () => {
  const swiperRef = React.useRef(null);
const {data} = useTestmonialQuery()

const handleMouseEnter = () => {
  swiperRef.current.swiper.autoplay.stop();
};

const handleMouseLeave = () => {
  swiperRef.current.swiper.autoplay.start();
};


  return (
    <>
    <section style={{backgroundColor: "#edecea"}}>
     <div className="container pt-3">
        <h2 className="heading text-center ">Our Clients Love us!</h2>
        <p className=' text-center test_para mx-auto '>Brighter Mind’s highly passionate team continuously works toward creating wealth for its clients by offering proprietary research and imparting knowledge to them.</p>

        <div className="row pt-3 pb-5">
          <div className="col-lg-7" onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
          <Swiper
          ref={swiperRef}
        spaceBetween={30}
        centeredSlides={true}
        navigation={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        scrollbar={{ draggable: true }}
        pagination={{
          clickable: true,
        }}
        
        modules={[Autoplay, Pagination, Navigation, FreeMode]}
        className="mySwiper"
      >
        {
          data && data?.data?.map((i) => (
        <SwiperSlide key={i?._id}>
        <div className="card bg-transparent d-flex flex-row align-items-center py-5  border-0 " >
            <div className=''>
                <h4 className=' fw-normal '>{i?.name}</h4>
                <p className=' text-dark-emphasis '>
                    {i?.description}
                </p>
            </div>
        </div>
        </SwiperSlide> 
           
          ))
        }
      
      </Swiper>
          </div>
          <div className="col-lg-5">
            <iframe
             className="w-100 mb-3"
             height="260"
             src="https://www.youtube.com/embed/xclT1kVtTas?si=gp5pzJoC2Ok_HG6n" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
            <div className='w-75 mx-auto'>
            <Link className='contact-header  text-white w-50  py-2 rounded-1 mx-auto  d-block  text-center '>View More</Link>
            </div>
        
          </div>
        </div>
        </div>
        </section>
    </>
  )
}

export default Testimonial