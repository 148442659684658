import React from 'react'
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import Footer from '../../components/partial/Footer';
import Navbar from '../../components/partial/Navbar';
const CyberSqurity = () => {
  return (
    <>
    <Navbar/>
    <div className="py-5 contact-header">
    <div className="  ps-5 text-white  ">
      <div>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link> <MdKeyboardArrowRight />
            </li>
            <li className="breadcrumb-item text-white " aria-current="page">
            Cyber Security
            </li>
          </ol>
        </nav>
      </div>
      <div>
        <h1> Cyber Security</h1>
      </div>
    </div>
  </div>
  <div className="container my-4 ">
    <h1 className=" text-center heading"> Cyber Security</h1>
    <p className='text-danger mt-3 fs-6'>
    GREETINGS,
GOVERNMENT OF INDIA HAS INITIATED A PROGRAM OF CYBER JAGROOKTA
(CYBER AWARENESS).
    </p>
    <p className='text-danger fs-6'>
    THIS MAIL IS IN THE SAME CONTEXT AND JUST TO REQUEST YOU TO BE
AWARE AND FOLLOW THE BELOW
MENTIONED BASIC CYBER HYGLENE PROTOCOLS
    </p>
   <div>
    <h4 className='text-danger fs-4 mb-3'><span className='border-2 border-primary border-bottom '>DO'S</span></h4>
    <ol>
        <li className='mb-2' style={{listStyleType: "unset"}}>
        Do enable card and online banking text notifications for transaction alerts,
one-time multi-factor codes, etc. Text notifications and codes are safer than
email.
        </li>
        <li className='mb-2' style={{listStyleType: "unset"}}>
        Do use strong passwords/passphrases and enable multi-factor verification
        (i.e., a secondary code sent to you as a text on your phone or email).
        </li>
        <li className='mb-2' style={{listStyleType: "unset"}}>
        Do update device operating systems, anti-virus, and browsers.
        </li>
        <li className='mb-2' style={{listStyleType: "unset"}}>
        Do secure your smartphone/tablet with a code to protect contacts and saved
        website logons.
        </li>
        <li className='mb-2' style={{listStyleType: "unset"}}>
        Do backup important documents to a secure location, such as a well-known
        cloud provider.
        </li>
        
    </ol>
   </div>
   <div>
    <h4 className='text-danger fs-4 mb-3'><span className='border-2 border-primary border-bottom '>DONT'S</span></h4>
    <ol>
        <li className='mb-2' style={{listStyleType: "unset"}}>
        Don’t click links or open attachments in emails/text messages unless you
        have confirmed the sender is valid.
        </li>
        <li className='mb-2' style={{listStyleType: "unset"}}>
        Don’t share card PIN or online banking password with anyone.
        </li>
        <li className='mb-2' style={{listStyleType: "unset"}}>
        Don’t share your social security number unless it’s absolutely necessary.
        </li>
        <li className='mb-2' style={{listStyleType: "unset"}}>
        Don’t accept funds from anyone with instructions to return/forward funds to
someone else or to buy gift cards.
        </li>
        <li className='mb-2' style={{listStyleType: "unset"}}>
        Don't lie to your financial institution when asked about taking money out of
your account. Fraudsters will purposely tell you to lie so that they can get you
to send them money and you could be at a loss.
        </li>
        
    </ol>
   </div>
   <div>
    <h5>Stay Aware. Be Secure Online...Always Keep Sensitive Information Protected</h5>
   </div>
  </div>
  <Footer/>
  </>
  )
}

export default CyberSqurity