import React from "react";
import { Link, useParams } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import { usePageDetailsQuery } from "../../redux/API/pageApi";
import { createMarkup } from "../constant/utils";
import Navbar from "../components/partial/Navbar";
import Footer from "../components/partial/Footer";
const Terms = () => {
const {id} = useParams()  
const {data} = usePageDetailsQuery(id)


  return (
    <>
    <Navbar/>
      <div className="py-5 contact-header">
        <div className="  ps-5 text-white  ">
          <div>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link> <MdKeyboardArrowRight />
                </li>
                <li className="breadcrumb-item text-white " aria-current="page">
                  {data?.data?.pagename}
                </li>
              </ol>
            </nav>
          </div>
          <div>
            <h1>{data?.data?.pagename}</h1>
          </div>
        </div>
      </div>
      <div className="container my-4">
        <h1 className=" text-center heading">{data?.data?.pagename}</h1>
        <p dangerouslySetInnerHTML={createMarkup(data?.data?.description)}></p>
      </div>
      <Footer/>
    </>
  );
};

export default Terms;
