import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { baseUrl } from "../../Constant/Matcher";



export const billApi = createApi({
    reducerPath: "billApi",
    baseQuery: fetchBaseQuery({baseUrl: `${baseUrl}`}),
    endpoints: (builder) => ({
        billInvoice: builder.query({
            query: (clientCode) => ({
             url: `client/billing?clientcode=${clientCode}`,
             method: "GET",
            })
         }),
         
    })
})

export const { useBillInvoiceQuery } = billApi