import React from 'react'
import '../../Pages/Style/Modal.css'
import { AiOutlineCheck } from "react-icons/ai";
import { useNavigate } from 'react-router-dom';
const SuccessModal = () => {
const navigate = useNavigate()

const handleNavigate = () => {
    navigate('/login')
}


  return (
    <>
      <div className="second-screen">
    <div class="Modal-card">
  <div class="icon">
   <AiOutlineCheck/>
  </div>
  <p class="text">Thank You for being a client with Brighter Mind!</p>
  <p class="text">Your Form has been sent for the approval and you will receive a call from our Principal Officer for first connect!
  <br />
  Also, you will receive user credentials on your registered mail id shortly.
  </p>
  <button className='button is-primary' onClick={handleNavigate}>Ok</button>
</div>
</div>
    </>
  )
}

export default SuccessModal