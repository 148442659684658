import React from 'react'
import { Link } from 'react-router-dom'
import Navbar from './front/components/partial/Navbar'
import Footer from './client/Components/Footer/Footer'

const PageNotFound = () => {
  return (
    <>
    <Navbar/>
    <div className="container d-flex align-items-center justify-content-center py-5 page-not-found">
       <div className="py-5 text-center">
       <h1>404</h1>
        <h2>Page Not Found</h2>
        <p className=' text-dark-emphasis mb-4'>We are sorry but the page you were looking for was not found!...</p>
        <Link to="/" className='login-btn text-white py-2 px-5 rounded-1 text-decoration-none mt-5'>HOME</Link>
       </div>
    </div>
    <Footer/>
    </>
  )
}

export default PageNotFound