import React from "react";
import img1 from "../images/page_header.png";
import { MdKeyboardArrowRight, MdEmail, MdCall } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { Link } from "react-router-dom";
import Navbar from "../components/partial/Navbar";
import Footer from "../components/partial/Footer";
import { Helmet } from "react-helmet-async";
const Contact = () => {
  return (
    <>
     <Helmet>
        <title>Contact US - Brighter Mind</title>
        <meta name="description" content="Contact us directly on mobile or drop a mail to discuss your investment queries with the Brighter mind team. You can also book an appointment with us online. Inquire Now! " />
      </Helmet>
    <Navbar/>
      <div className="contact-page w-100">
        <div className="contact-banner position-relative ">
          <img src={img1} alt="" className="w-100 h-100" />
          <div className=" position-absolute top-50 translate-middle-y ps-5 text-white  ">
            <div>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link> <MdKeyboardArrowRight />
                  </li>
                  <li className="breadcrumb-item text-white " aria-current="page">
                    Contact us
                  </li>
                </ol>
              </nav>
            </div>
            <div>
              <h1>Contact Us</h1>
            </div>
          </div>
        </div>
        <div className="container my-5">
          <div className="row">
            <div className="col-lg-6">
              <h1 className="heading text-center ">Contact Us</h1>
              <p>
                If you have any queries or just want to chat with us on the
                equity investment, we will always be happy to connect. Feel free
                to contact us by filling up the form and we will surely get back
                to you as soon as possible!
              </p>
              <div className=" d-flex  justify-content-between my-4">
                <div className="d-flex ">
                  <div className=" me-3 fs-3 text-dark-emphasis">
                    <MdEmail />
                  </div>
                  <div>
                    <h4 className="m-0">Email Us</h4>
                    <span>contact@brightermind.in</span>
                  </div>
                </div>
                <div className="d-flex ">
                  <div className=" me-3 fs-3 text-dark-emphasis">
                    <MdCall />
                  </div>
                  <div>
                    <h4 className="m-0">Call Us</h4>
                    <span>+91-8800308006</span>
                  </div>
                </div>
              </div>
              <hr />
              <h1 className="heading text-center ">Reach Us</h1>
              <div className="d-flex my-4">
                <div className=" me-3 fs-3 text-dark-emphasis ">
                  <FaLocationDot />
                </div>
                <div>
                  <h4 className="m-0">Corporate Office</h4>
                  <span>
                    305, Tower 4, Assotech Business Cresterra, Sector 135,
                    Noida, Uttar Pradesh - 201304.
                  </span>
                </div>
              </div>
              <div className="d-flex ">
                <div className=" me-3 fs-3 text-dark-emphasis">
                  <FaLocationDot />
                </div>
                <div>
                  <h4 className="m-0">Registered Office</h4>
                  <span>
                  53-B, U.G.Floor, Vijay Block, Laxmi Nagar,
                  New Delhi - 110092
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="card d-flex  justify-content-center  align-items-center  py-3 text-white contact-header mb-4">
                <p className="m-0">Book an Online Appointment</p>
              </div>
              <div className="card contact-form-body py-3 px-4">
                <form className="row g-2">
                  <div className="col-12 col-lg-6 col-md-6">
                    <label htmlFor="inputEmail4" className="form-label text-white ">
                      Your Name
                    </label>
                    <input type="text" className="form-control" id="inputEmail4" />
                  </div>
                  <div className="col-12 col-lg-6 col-md-6">
                    <label htmlFor="inputEmail4" className="form-label text-white ">
                      Your Email
                    </label>
                    <input type="email" className="form-control" id="inputEmail4" />
                  </div>
                  <div className="col-12 col-lg-6 col-md-6">
                    <label htmlFor="inputPassword4" className="form-label text-white">
                      Your Phone
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="inputPassword4"
                    />
                  </div>
                  <div className="col-12 col-lg-6 col-md-6">
                    <label htmlFor="inputPassword4" className="form-label text-white">
                      Existing Customer?
                    </label>
                    <div className="d-flex">
                      <div className="form-check me-2">
                        <label className="form-check-label text-white " htmlFor="flexRadioDefault1">
                          Yes
                        </label>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                          checked
                        />
                      </div>
                      <div className="form-check">
                        <label className="form-check-label text-white " htmlFor="flexRadioDefault2">
                          No
                        </label>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault2"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <label htmlFor="inputPassword4" className="form-label text-white">
                      Your City
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="inputPassword4"
                    />
                  </div>
                  <div className="col-12">
                    <label htmlFor="inputPassword4" className="form-label text-white">
                    Your message (optional)
                    </label>
                    <textarea name="" id="" cols="20" rows="3" className="w-100"></textarea>
                  </div>

                  <div className="col-12">
                    <button type="submit" className=" bg-black  text-white py-2 px-4 border-0  rounded-1  mx-auto d-block">
                      Send
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3506.4434473751853!2d77.39928990919553!3d28.496303075638163!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce860bfea95d9%3A0x683ddf48f263ecc!2sAssotech%20Business%20Cresterra!5e0!3m2!1sen!2sin!4v1711622157667!5m2!1sen!2sin" height="450" style={{border:"0", width: "100%"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title="map-box"></iframe>
      </div>
      <Footer/>
    </>
  );
};

export default Contact;
