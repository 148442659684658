import React from 'react'
import { Link } from 'react-router-dom'

const Thirdform = (props) => {


  return (
    <>
    <div className="third-form ">
    <div className="objective-content third">
        <div className="objective-body">
        <div className="third-container">
            <label htmlFor="" className='d-block score-label'>Your Risk Profile Score: </label>
            <span className='w-100 d-block text-center py-1 text-uppercase highlight text-white text-decoration-underline fs-4' style={{backgroundColor: "#9DC913"}}>{props.score}</span>
            <hr />
            <h5 className='text-center'>Document Upload</h5>
            <p className="text-danger mb-3 text-center">{props.feildError}</p>
		<form>
            <div>
			<label htmlFor="profile">Photo:</label>
			<input required  className="inpdddut" name="profile" id="profile" type="file" onChange={props.handleFilechange}/>
            </div>
            <div>
			<label htmlFor="panNumber">PAN Card:</label> 
			<input required className="inpdddut" name="panCard" id="panCard" type="file" onChange={props.handleFilechange}/>
            </div>
            <div>
			<label htmlFor="aadharCard">Aadhar Card:</label>
			<input required  className="inpdddut" name="aadharCard" id="aadharCard" type="file" onChange={props.handleFilechange}/>
            </div>
            <div>
			<label htmlFor="cheque">Cancelled Cheque:  <p>(Optional)</p></label> 
			<input required  className="inpdddut" name="cheque" id="cheque" type="file" onChange={props.handleFilechange}/>
            </div>
            <div>
			<label htmlFor="signature">Signature:</label>
			<input required  className="inpdddut" name="signature" id="signature" type="file" onChange={props.handleFilechange}/>
            </div>
            <div className='d-flex justify-content-between align-items-center'>
			<input className="inpdddut check" name="terms" id="terms" type="checkbox" required checked={props.termsChecked} onChange={props.handleCheckboxChange}/>
			<label htmlFor="terms " className='terms-condition'>Agree Brighter Mind T&C</label>
           
            </div>
        <button className="submit" onClick={props.handleDocumentSubmit}>submit</button>
		</form>
	</div>
        </div>
    </div>
    </div>
    </>
  )
}

export default Thirdform