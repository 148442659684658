import React from 'react'
import { Doughnut } from 'react-chartjs-2'

const DoughnutChart = ({doughnutChartData}) => {


  return (
    <>
    <Doughnut data={doughnutChartData}/>
    </>
  )
}

export default DoughnutChart