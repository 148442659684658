import React, { useState } from "react";

const Objectivecard = (props) => {
  const [options, setOptions] = useState([
    { id: 1, text: "Capital Growth", rank: "" },
    { id: 2, text: "Capital Security / Capital Preservation", rank: "" },
    { id: 3, text: "Balanced - Preservation & Growth", rank: "" },
  ]);

 
  const handleRankChange = (optionId, newRank) => {
    const updatedOptions = options.map((option) =>
      option.id === optionId ? { ...option, rank: newRank } : option
    );

    setOptions(updatedOptions);
  };

  const isAllRanked = options.every((option) => option.rank !== '');

  const validateRanks = () => {
    const ranks = options.map((option) => option.rank);
    const uniqueRanks = new Set(ranks);
    return isAllRanked && uniqueRanks.size === 3;
  };

  const handleObjective = (e) => {
    e.preventDefault()
    if(validateRanks()){
      props.setShowSecondScreen(false);
      props.setShowQuestion(true);
    }
    else{
     console.log("Error");
    }
  };


  return (
    <>
    <div className="second-screen">
      <div className="objective-content ">
        <h4 >Investment Objective</h4>
        <div className="objective-body">
        {validateRanks() ? null :    <p className="objective-desc mb-4">
            Rank your investment objective in order of priority, from 1-3.
          </p>}
          <form action="">
            {options?.map((option) => {
              return (
                <div className="objective-options" key={option.id}>
                  <label htmlFor="photo" className="mb-0">
                    {option?.text} <sup>*</sup>
                  </label>
                  <input
                    className="mb-0"
                    name="photo"
                    id="photo"
                    type="text"
                    maxLength={2}
                    value={option.rank}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      const enteredRanks = options.map((opt) => opt.rank).filter((rank) => rank !== option.rank);
                      
                      if (['1', '2', '3', ''].includes(inputValue) && !enteredRanks.includes(inputValue)) {
                        handleRankChange(option.id, inputValue);
                      }
                    }}
                  />
                </div>
              );
            })}
            <button className="submit" onClick={handleObjective}>
              proceed
            </button>
          </form>
        </div>
      </div>
      </div>
    </>
  );
};

export default Objectivecard;
