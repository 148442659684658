import React from 'react'
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import Navbar from '../components/partial/Navbar';
import Footer from '../components/partial/Footer';
import { Helmet } from 'react-helmet-async';

const Video = () => {
  return (
    <>
     <Helmet>
        <title>Videos - Brighter Mind</title>
        <meta name="description" content="We cover the latest updates on the equity market and trending topics in our videos. Also learn how to stay invested in equity market so as to build wealth in long term. Watch Now" />
      </Helmet>
    <Navbar/>
      <div className="py-5 contact-header">
        <div className="  ps-5 text-white  ">
          <div>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link> <MdKeyboardArrowRight />
                </li>
                <li className="breadcrumb-item text-white " aria-current="page">
                Videos
                </li>
              </ol>
            </nav>
          </div>
          <div>
            <h1>Videos</h1>
          </div>
        </div>
      </div>
      <div className="container mt-5">
        <div className="row">
          <div className="col-lg-4 col-md-6">
          <iframe width="360" height="203" src="https://www.youtube.com/embed/27NsTWDRRkk" title="Brighter Mind Youtube Channel Trailer" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
          <div className="col-lg-4 col-md-6">
          <iframe width="360" height="203" src="https://www.youtube.com/embed/eLihmF47k6o" title="Tata Coffee and Tata Consumer Merger Full Analysis | Brighter Mind" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
          <div className="col-lg-4 col-md-6">
          <iframe width="360" height="203" src="https://www.youtube.com/embed/lNu9UTp2bOE" title="HDFC Ltd and HDFC Bank Merger – Full Analysis – Detailed Review | Brighter Mind" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
          <div className="col-lg-4 col-md-6">
          <iframe width="360" height="203" src="https://www.youtube.com/embed/yJJX4N8wgsE" title="Global and Indian Market outlook" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
          <div className="col-lg-4 col-md-6">
          <iframe width="360" height="203" src="https://www.youtube.com/embed/8mQPHIPHAhs" title="Macro-Economic Analysis - Indian Equity Market Outlook - Global Overview" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
          <div className="col-lg-4 col-md-6">
          <iframe width="360" height="203" src="https://www.youtube.com/embed/bKWwfc5bEM4" title="What is inflation – How Inflation impacts your stocks – Rate of Inflation | Brighter Mind" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
          <div className="col-lg-4 col-md-6">
          <iframe width="360" height="203" src="https://www.youtube.com/embed/3htOY-dIhsg" title="Interest rate rise and sector Impact | Brighter Mind" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
          <div className="col-lg-4 col-md-6">
          <iframe width="360" height="203" src="https://www.youtube.com/embed/jK7K-RQND24" title="Cryptocurrency Crash – Impact on Equity Market | Brighter Mind" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
          <div className="col-lg-4 col-md-6">
          <iframe width="360" height="203" src="https://www.youtube.com/embed/gE0WTA-fwpU" title="IT Sector Outlook after TCS Result – Retail Sector Forecast  | Brighter Mind" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
          <div className="col-lg-4 col-md-6">
          <iframe width="360" height="203" src="https://www.youtube.com/embed/PooVHd3jY6g" title="US High Inflation data – Impact on Sectors and Companies | Brighter Mind" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
          <div className="col-lg-4 col-md-6">
          <iframe width="360" height="203" src="https://www.youtube.com/embed/BF9MmCR5Zew" title="India’s $5 trillion GDP vision - Free Trade Agreement and Indian Economy" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
          <div className="col-lg-4 col-md-6">
          <iframe width="360" height="203" src="https://www.youtube.com/embed/VSGMbSFHdpY" title="Why Investing is Important - Stock market investments for Beginners" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
          <div className="col-lg-4 col-md-6">
          <iframe width="360" height="203" src="https://www.youtube.com/embed/8wjUCjYZ2IQ" title="What is Asset Allocation? How to do Asset Allocation the Right Way? | Brighter Mind" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
          <div className="col-lg-4 col-md-6">
          <iframe width="360" height="203" src="https://www.youtube.com/embed/o5UHFkH4EU4" title="How to Invest in Stocks for Beginners - How to Pick Stocks | Brighter Mind" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
          <div className="col-lg-4 col-md-6">
          <iframe width="360" height="203" src="https://www.youtube.com/embed/p55aL_D-m5k" title="Piramal Enterprises Demerger - Piramal Pharma Valuation | Brighter Mind" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
          <div className="col-lg-4 col-md-6">
          <iframe width="360" height="203" src="https://www.youtube.com/embed/DohOpUHAkwU" title="Shipping Corporation Of India Disinvestment Opportunity - SCI Demerger News | Brighter Mind" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
          <div className="col-lg-4 col-md-6">
          <iframe width="360" height="203" src="https://www.youtube.com/embed/_76w2_1bDDw" title="DFM Foods Delisting - DFM Foods Stock Latest News - DFM Crax Full Analysis" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
          <div className="col-lg-4 col-md-6">
          <iframe width="360" height="203" src="https://www.youtube.com/embed/gr5NAapPb0s" title="Container Corporation Of India Divestment - Impact Of Rail Land Lease Policy" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
          <div className="col-lg-4 col-md-6">
          <iframe width="360" height="203" src="https://www.youtube.com/embed/Tzew7KqIueQ" title="Adani Group To Launch Open Offer To Acquire NDTV | Adani buys NDTV| Brighter Mind" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
          <div className="col-lg-4 col-md-6">
          <iframe width="360" height="203" src="https://www.youtube.com/embed/7lvmQFO9gcg" title="Allcargo Logistics Latest News - National Logistic Policy | Brighter Mind" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  )
}

export default Video