import React from "react";
import WinnerCard from "../partial/WinnerCard";
import data from '../../data.json'
import img1 from '../../images/logo1.svg'
import img2 from '../../images/logo2.png'
import img3 from '../../images/logo3.png'
import img4 from '../../images/logo4.png'
import img5 from '../../images/logo5.png'
import img6 from '../../images/logo6.png'
import img7 from '../../images/logo7.png'
import img8 from '../../images/logo8.png'
import img9 from '../../images/logo9.png'
import img10 from '../../images/logo10.svg'
import img11 from '../../images/logo11.png'
import img12 from '../../images/logo12.png'
const Winner = () => {

  const imageMap = {
    "logo1.svg": img1,
    "logo2.png": img2,
    "logo3.png": img3,
    "logo4.png": img4,
    "logo5.png": img5,
    "logo6.png": img6,
    "logo7.png": img7,
    "logo8.png": img8,
    "logo9.png": img9,
    "logo10.svg": img10,
    "logo11.png": img11,
    "logo12.png": img12,
  };


  return (
    <>
    <div style={{backgroundColor: "#edecea"}} className="pt-2 pb-5">
      <div className="container mt-3 winner-section position-relative">
        <h2 className="heading text-center mb-4">Our Winner Stocks</h2>
        <div className="row gy-5 ">
          {
            data?.stocks?.map((i, index) => (
              <div className="col-lg-4 col-md-6 px-md-4" key={index}>
              <WinnerCard
              topBorderColor={i?.topBorderColor}
              title={i?.industry}
              price={i?.price}
              cmp={i?.CMP}
              sector={i?.sector}
              textColor={i?.textColor}
              img={imageMap[i.img]}
              imgWidth={i?.imgWidth}
              />
            </div>
          
            ))
          }
         
         
        </div>
        
      </div>
      </div>
     
    </>
  );
};

export default Winner;
