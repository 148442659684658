import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { baseUrl } from "../../Constant/Matcher";



export const supportApi = createApi({
    reducerPath: "supportApi",
    baseQuery: fetchBaseQuery({baseUrl: `${baseUrl}`}),
    endpoints: (builder) => ({
        createSupport: builder.mutation({
            query: (formData) => ({
              url: `/helpandsupport/createsupport`,
              method: "POST",
              body: formData,
            }),
          }), 
          createConversation: builder.mutation({
            query: (formData) => ({
              url: `/helpandsupport/startconversation`,
              method: "POST",
              body: formData,
            }),
          }), 
        getSupport: builder.query({
            query: (userId) => ({
             url: `/helpandsupport/supportlist?userid=${userId}`,
             method: "GET",
            })
         }),
         getConversation: builder.query({
          query: (conversationId) => ({
           url: `/helpandsupport/getconversation?conversationId=${conversationId}`,
           method: "GET",
          })
       }),
         
    })
})

export const { useCreateSupportMutation, useCreateConversationMutation, useGetSupportQuery, useGetConversationQuery } = supportApi