import React, { useContext, useEffect, useState} from "react";

import "./Style/viewtransaction.css";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Feedback } from "./Feedback";
import SideBar from "../Components/SideBar/SideBar";
import TextLinkExample from "../Components/NavBar/NavBar";
import { Link, useParams } from "react-router-dom";
import "./Style/viewtransaction.css";
import { SidebarContext } from "../../context/SidebarContext";

import {
  usePortfolioListQuery,
  useTransectionViewQuery,
} from "../../redux/API/portfolioApi";
import Footer from "../Components/Footer/Footer";
import { formatDate, formatIndianNumber } from "../../Constant/utils";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function ViewTransaction() {
  const { isSiderbaropen } = useContext(SidebarContext);
  const { clientcode, stock, stockname, qty } = useParams();
  const clientCode = localStorage.getItem("clientCode");
  const { data: portfolioListDetails, isLoading } = useTransectionViewQuery({
    clientcode: clientcode,
    stock: stock,
  });
  const { data: portfolioList } = usePortfolioListQuery(clientCode);

  const sumOfAmounts =
    portfolioList &&
    portfolioList?.data?.reduce((sum, item) => sum + item?.avg * item?.qty, 0);

  const result = [];
  let prevtotalholdingvalue = 0;
  let prevtotalholdingqty = 0;
  let totalholdingqty = 0;
  let totalholdingvalue = 0;

  portfolioListDetails?.data?.forEach((row, index) => {
    totalholdingvalue +=
      (parseInt(row.buyval) > 0 ? parseInt(row.buyval) : 0) -
      (parseInt(row.SellVal) > 0 ? parseInt(row.SellVal) : 0);
    totalholdingqty +=
      (parseInt(row.buyqty) > 0 ? parseInt(row.buyqty) : 0) -
      (parseInt(row.SellQty) > 0 ? parseInt(row.SellQty) : 0);
    result.push(
      <StyledTableRow>
        <StyledTableCell>{index + 1}</StyledTableCell>
        <StyledTableCell>{formatDate(row.trasactiondate)}</StyledTableCell>
        <StyledTableCell>{row?.scripname}</StyledTableCell>
        <StyledTableCell>
          {row.buyqty > 0 ? row.buyqty : -1 * row.SellQty}
        </StyledTableCell>
        <StyledTableCell>
          {row.buyval > 0 ? formatIndianNumber(row.buyval / row.buyqty) : formatIndianNumber(row.SellVal / row.SellQty)}
        </StyledTableCell>
        <StyledTableCell>
          {row.buyval > 0 ? formatIndianNumber(Number((row.buyval))) : formatIndianNumber(-1 * row.SellVal)}
        </StyledTableCell>
       
      </StyledTableRow>
    );
    prevtotalholdingvalue +=
      (parseInt(row.buyval) > 0 ? parseInt(row.buyval) : 0) -
      (parseInt(row.SellVal) > 0 ? parseInt(row.SellVal) : 0);
    prevtotalholdingqty +=
      (parseInt(row.buyqty) > 0 ? parseInt(row.buyqty) : 0) -
      (parseInt(row.SellQty) > 0 ? parseInt(row.SellQty) : 0);
  });


  const totalCurrentValue = portfolioList?.data?.reduce((acc, result) => {
    const currentprice = result?.stocklist?.currentprice;
    return acc + (currentprice ? currentprice * result.qty : 0);
  }, 0);
  

  return (
    <div className="App">
      <SideBar />
      <div className={!isSiderbaropen ? "pages" : "pages-change"}>
        <TextLinkExample />
        <div className="ViewTransaction">
          <div className="container px-4 mt-4">
            <div className="d-flex">
              <h3>Transaction</h3>
              <Link to="/client/portfoliodetail">
                {" "}
                <p>
                  <i className="fa-solid fa-right-from-bracket fa-rotate-180"></i>{" "}
                  Go Back
                </p>
              </Link>
            </div>
            <div className="UpperSec my-4">
              <span>Stock Name:</span>
              <p className="mx-4 px-2">{stockname}</p>
            </div>
          </div>
          <div className="container px-3">
          <div className="row  ">
            <div className="col-md-6">
              <div className="card ProfileDetail-card  row-1">
                <div className="mx-4">
                  <h5 className="card-title"> Invested Value</h5>
                  <h2 className="Values">₹ {formatIndianNumber(Math.round(sumOfAmounts))}</h2>
                </div>
                <img
                  src={require("../Components/photos/Animation - 1697460328274.gif")}
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="card ProfileDetail-card row-1">
                <div className="mx-4">
                  <h5 className="card-title">Current Value</h5>
                  <h2 className="Values">₹ {formatIndianNumber(Math.round(totalCurrentValue))}</h2>
                </div>
                <img
                  src={require("../Components/photos/Animation - 1697459940103.gif")}
                  alt=""
                />
              </div>
            </div>
          </div>
          </div>
          <div
            className="portfolio-detail-table my-5 col-12 px-3"
            style={{ margin: "auto" }}
          >
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>S.No.</StyledTableCell>
                    <StyledTableCell>Date</StyledTableCell>
                    <StyledTableCell>Stock Name</StyledTableCell>
                    <StyledTableCell>QTY</StyledTableCell>
                    <StyledTableCell>Price&nbsp;(₹)</StyledTableCell>
                    <StyledTableCell>Val</StyledTableCell>
                   
                  </TableRow>
                </TableHead>
                {isLoading ? (
                  <TableBody>
                     <TableRow>
                    
                    <StyledTableCell className="text-center" colSpan={6}>Loading...</StyledTableCell>
                    
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    {result}

                    {!isLoading && portfolioListDetails?.data?.length === 0 && (
                      <TableRow>
                        <StyledTableCell className="text-center" colSpan={6}>No Record found</StyledTableCell>
                      </TableRow>
                    )}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </div>
        </div>
        <Footer />
      </div>
      <Feedback />
    </div>
  );
}
