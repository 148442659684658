import React from "react";
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import Footer from "../components/partial/Footer";
import Navbar from "../components/partial/Navbar";

const Privacy = () => {
  return (
    <>
    <Navbar/>
      <div className="py-5 contact-header">
        <div className="  ps-5 text-white  ">
          <div>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link> <MdKeyboardArrowRight />
                </li>
                <li className="breadcrumb-item text-white " aria-current="page">
                  Privacy Policy
                </li>
              </ol>
            </nav>
          </div>
          <div>
            <h1>Privacy Policy</h1>
          </div>
        </div>
      </div>
      <div className="container my-4">
        <h1 className=" text-center heading">Privacy Policy</h1>
        <p>
          www.brightermind.in is committed to protecting your privacy and work
          towards offering you a safe online experience. To avail some of the
          services offered by www.brightermind.in, you need to sign up by giving
          simple details like name, contact number and email address that
          personally identifies you. We need this minimal information to operate
          our online platform. This information helps us to keep you updated
          about the latest content on the website and stay in touch with you.
        </p>
        <p>
          www.brightermind.in does not collect personal information about
          individuals except when such individuals specifically provide such
          information on a voluntary basis. This can be done through
          registration for contests, newsletter subscriptions and other online
          activities. Personal information of users of www.brightermind.in will
          not be sold or otherwise transferred to unaffiliated third parties
          unless otherwise stated at the time of collection or without the
          user’s consent.
        </p>
        <p>
          We treat your personal information or your use of the service as
          private and confidential and does not reveal it to any third parties
          unless required by law and except where it believes in good faith,
          such action is necessary to comply with the applicable regulatory
          processes, or to protect and defend the rights of other users or to
          enforce the terms of service which are binding on all the users of
          www.brightermind.in.
        </p>
        <p>
          We will not send you any unsolicited information except where
          specifically agreed or necessary for operational or regulatory
          reasons. However, in order to maximize your benefits messages relating
          to our products and services, additions and improvements to the
          products and services offered may be sent to you. If the user wants to
          opt out of receiving promotional and marketing information about other
          products, services, they may withdraw the consent for us to contact
          you. We may use any email addresses of users to send occasional emails
          pertaining to the said information. However the user can unsubscribe
          from receipt of such emails by following instructions therein or by
          communicating accordingly through www.brightermind.in.
        </p>
        <p>
          www.brightermind.in may collect unnamed statistics, which does not
          personally identify you and reserves the right to perform statistical
          analyses of user behaviour.
        </p>

        <p>
          Users are also made aware that certain information and data may be
          automatically collected through the standard operation of
          www.brightermind.in’s internet servers or through the use of cookies.
          Cookies do not personally identify you, but they do identify your
          computer. If you choose to be a registered user of
          www.brightermind.in, you must accept a cookie from the website.
        </p>

        <p>
          To protect your personal information, we follow industry best
          practices to make sure it is not inappropriately lost, misused,
          accessed, disclosed, altered or destroyed.
        </p>

        <p>
          By using this site, you intend that you are at least the age of
          majority. www.brightermind.in is not intended for use by minors.
          Parents are requested to ensure that personal information is not
          provided by minors on this website. If done, this site will consider
          that the parent has given us the consent to allow any use of this site
          by the minors.
        </p>

        <p>
          We reserve the right to change the privacy policy at any time. If
          www.brightermind.in decides to change this policy, such material
          changes will be updated on this page as soon as possible. Users may
          note that any change in the privacy policy will be effective from the
          date announced by the company and not from the date of posting on this
          page.
        </p>
        <p>
          The statement and the policies outlined herein are not intended to and
          do not create any contractual or legal rights in favour of any user of
          www.brightermind.in or on behalf of any other party.
        </p>
        <p>
          However, users should note that by using this website, they are deemed
          to have consented to the collection and use of information by
          www.brightermind.in as stated above.
        </p>
      </div>
      <Footer/>
    </>
  );
};

export default Privacy;
