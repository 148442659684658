import { useReducer } from "react";
import { createContext } from "react";
import reducer from "../redux/reducer/sidebarReducer";
import PropTypes from "prop-types";

const initialState = {
    isSiderbaropen: false
}

export const SidebarContext = createContext({})
export const SidebarProvider = ({ children }) => {
    const [ state, dispatch ] = useReducer(reducer, 
        initialState)

    const toggleSidebar = () => {
        dispatch({
            type: "TOGGLE_SIDEBAR"
        })
    }
    return (
        <SidebarContext.Provider value={{
            ...state,
            toggleSidebar
        }}>
            {children}
        </SidebarContext.Provider> 
    )
}
SidebarProvider.propTypes = {
    children: PropTypes.node
}