
const sidebarReducer = (state, action) => {
 if(action.type === "TOGGLE_SIDEBAR"){
    return {
        ...StaticRange,
        isSiderbaropen: !state.isSiderbaropen
    }
 }
 throw new Error(`No matching ${action.type} action type`)
}

export default sidebarReducer