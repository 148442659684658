import React from 'react'
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const Privateroutes = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const referral = params.get('referral');
  const encodedUserId = params.get('refid');

const isAuthenticated = useSelector((state) => state.myreducer.isAuthenticated)

if(isAuthenticated){
  return <Outlet/>
}
else{
  const redirectTo = referral ? `/registration?refid=${encodedUserId}&referral=${referral}` : '/registration';
    return <Navigate to={redirectTo} />;
}

}

export default Privateroutes