import React from 'react'
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import Footer from '../../components/partial/Footer';
import Navbar from '../../components/partial/Navbar';
const InvestorCharter = () => {
  return (
    <>
    <Navbar/>
    <div className="py-5 contact-header">
    <div className="  ps-5 text-white  ">
      <div>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link> <MdKeyboardArrowRight />
            </li>
            <li className="breadcrumb-item text-white " aria-current="page">
            Investor Charter
            </li>
          </ol>
        </nav>
      </div>
      <div>
        <h1>Investor Charter</h1>
      </div>
    </div>
  </div>
  <div className="container my-4 ">
    <h1 className=" text-center heading"> Investor Charter</h1>
    <p className='mt-3 fs-6'>
    Investor Charter in respect of Investment Adviser (IA) as per SEBI Circular
SEBI/HO/IMD/IMD-II CIS/P/CIR/2021/0686 dated 13th December, 2021
    </p>
    <p className=' fs-6 fw-semibold text-decoration-underline'>
    A. VISION AND MISSION STATEMENTS FOR INVESTORS
    </p>
    <ul>
        <li className='fw-semibold text-decoration-underline'> VISION</li>
        <li>Invest with knowledge & safety.</li>
        <li className='fw-semibold text-decoration-underline'>MISSION</li>
    </ul>
    <p>
    Every investor should be able to invest in right investment products based on their needs, manage
    and monitor them to meet their goals, access reports and enjoy financial wellness.
    </p>
    <p className=' fs-6 fw-semibold text-decoration-underline'>
    B. DETAILS OF BUSINESS TRANSACTED BY THE INVESTMENT ADVISER WITH RESPECT TO
THE INVESTORS

    </p>
   <div>
    <ul>
        <li className='mb-2' style={{listStyleType: "disc"}}>To enter into an agreement with the client providing all details including fee details, aspect
        of Conflict of interest disclosure and maintaining confidentiality of information</li>
        <li className='mb-2' style={{listStyleType: "disc"}}>To do a proper and unbiased risk – profiling and suitability assessment of the client.</li>
        <li className='mb-2' style={{listStyleType: "disc"}}>To obtain registration with Know Your Client Registration Agency (KRA) and Central Know
          Your Customer Registry (CKYC).</li>
        <li className='mb-2' style={{listStyleType: "disc"}}>To conduct audit annually.</li>
        <li className='mb-2' style={{listStyleType: "disc"}}>To disclose the status of complaints in its website.</li>
         <li className='mb-2' style={{listStyleType: "disc"}}>To disclose the name, proprietor name, type of registration, registration number, validity, complete address with tele phone numbers and associated SEBI regional/local Office details
          in its website.</li>
         <li className='mb-2' style={{listStyleType: "disc"}}>To employ only qualified and certified employees.</li>
         <li className='mb-2' style={{listStyleType: "disc"}}>To deal with clients only from official number.</li>
         <li className='mb-2' style={{listStyleType: "disc"}}>To maintain records of interactions, with all clients including prospective clients (prior to
          onboarding), where any conversation related to advice has taken place.</li>
    </ul>
    <p className=' fs-6 fw-semibold text-decoration-underline'>
    C. DETAILS OF SERVICES PROVIDED TO INVESTORS (NO INDICATIVE TIMELINES)
    </p>
    <ul >
      <li className='fw-semibold text-decoration-underline' style={{listStyleType: "disc"}}>ONBOARDING OF CLIENTS</li>
      <ul className='mb-3'>
        <li style={{listStyleType: "circle"}}>Sharing of agreement copy</li>
        <li style={{listStyleType: "circle"}}>Completing KYC of clients</li>
        </ul>
        <li className='fw-semibold text-decoration-underline' style={{listStyleType: "disc"}}>DISCLOSURE TO CLIENTS</li>
        <ul className='mb-3'>
        <li style={{listStyleType: "circle"}}>To provide full disclosure about its business, affiliations, compensation in the
        agreement.</li>
        <li style={{listStyleType: "circle"}}>To not access client’s accounts or holdings for offering advice.</li>
        <li style={{listStyleType: "circle"}}>To disclose the risk profile to the client.</li>
        </ul>
        <li style={{listStyleType: "disc"}}>To provide investment advice to the client based on the risk-profiling of the clients and
        suitability of the client.</li>
    </ul>
    <p className=' fs-6 fw-semibold text-decoration-underline'>
    D. DETAILS OF GRIEVANCE REDRESSAL MECHANISM AND HOW TO ACCESS IT
    </p>
    <ol>
      <li className='mb-2' style={{listStyleType: "unset"}}>In case of any grievance / complaint, an investor should approach the concerned
      Investment Adviser and shall ensure that the grievance is resolved within 30 days.
      </li>
      <li className='mb-2' style={{listStyleType: "unset"}}>If the investor’s complaint is not redressed satisfactorily, one may lodge a complaint with
SEBI on SEBI’s 'SCORES' portal which is a centralized web based complaints redressal
system. SEBI takes up the complaints registered via SCORES with the concerned
intermediary for timely redressal. SCORES facilitates tracking the status of the complaint.</li>
      <li className='mb-2' style={{listStyleType: "unset"}}>
      With regard to physical complaints, investors may send their complaints to: Office of
Investor Assistance and Education, Securities and Exchange Board of India, SEBI Bhavan,
Plot No. C4-A, ‘G’ Block, Bandra-Kurla Complex, Bandra (E), Mumbai - 400 051.
      </li>
    </ol>
    <p className='mb-3 fs-6 fw-semibold text-decoration-underline'>
    E. EXPECTATIONS FROM THE INVESTORS (RESPONSIBILITIES OF INVESTORS)
    </p>
    <p className=' fs-6 fw-semibold text-decoration-underline'> DO’S</p>
    <ol>
    <li className='mb-2' style={{listStyleType: "lower-roman"}}>Always deal with SEBI registered Investment Advisers.</li>
    <li className='mb-2' style={{listStyleType: "lower-roman"}}> Ensure that the Investment Adviser has a valid registration certificate</li>
    <li className='mb-2' style={{listStyleType: "lower-roman"}}>Check for SEBI registration number. <br />
    Please refer to the list of all SEBI registered Investment Advisers which is available
    on SEBI website in the following link: <br />
    <a className='text-primary text-decoration-underline' target='_blank' href="https://www.sebi.gov.in/sebiweb/other/OtherAction.do?doRecognisedFpi=yes&intmId=13" rel='noreferrer'>https://www.sebi.gov.in/sebiweb/other/OtherAction.do?doRecognisedFpi=yes&intmId=13</a>
     </li>
    <li className='mb-2' style={{listStyleType: "lower-roman"}}>Pay only advisory fees to your Investment Adviser. Make payments of advisory fees
through banking channels only and maintain duly signed receipts mentioning the
details of your payments.</li>
    <li className='mb-2' style={{listStyleType: "lower-roman"}}>Always ask for your risk profiling before accepting investment advice. Insist that
Investment Adviser provides advisory strictly on the basis of your risk profiling and
take into account available investment alternatives.</li>
    <li className='mb-2' style={{listStyleType: "lower-roman"}}>Ask all relevant questions and clear your doubts with your Investment Adviser
    before acting on advice.</li>
    <li className='mb-2' style={{listStyleType: "lower-roman"}}>Assess the risk–return profile of the investment as well as the liquidity and safety
    aspects before making investments.</li>
    <li className='mb-2' style={{listStyleType: "lower-roman"}}>Insist on getting the terms and conditions in writing duly signed and
stamped. Read these terms and conditions carefully particularly regarding advisory
fees, advisory plans, category of recommendations etc. before dealing with any
Investment Adviser.
</li>
    <li className='mb-2' style={{listStyleType: "lower-roman"}}>Be vigilant in your transactions.</li>
    <li className='mb-2' style={{listStyleType: "lower-roman"}}>Approach the appropriate authorities for redressal of your doubts / grievances.</li>
    <li className='mb-2' style={{listStyleType: "lower-roman"}}>Inform SEBI about Investment Advisers offering assured or guaranteed returns.</li>
   
    </ol>
    <p className=' fs-6 fw-semibold text-decoration-underline'>DON’TS</p>
    <ol>
    <li className='mb-2' style={{listStyleType: "lower-roman"}}>Don’t fall for stock tips offered under the pretext of investment advice.</li>
    <li className='mb-2' style={{listStyleType: "lower-roman"}}>Do not provide funds for investment to the Investment Adviser. </li>
    <li className='mb-2' style={{listStyleType: "lower-roman"}}>Don’t fall for the promise of indicative or exorbitant or assured returns by the
    Investment Advisers. Don’t let greed overcome rational investment decisions.</li>
    <li className='mb-2' style={{listStyleType: "lower-roman"}}>Don’t fall prey to luring advertisements or market rumors.</li>
    <li className='mb-2' style={{listStyleType: "lower-roman"}}>Avoid doing transactions only on the basis of phone calls or messages from any
Investment adviser or its representatives.
</li>
    <li className='mb-2' style={{listStyleType: "lower-roman"}}>Don’t take decisions just because of repeated messages and calls by Investment
    Advisers.</li>
    <li className='mb-2' style={{listStyleType: "lower-roman"}}>Do not fall prey to limited period discount or other incentive, gifts, etc. offered by
    Investment advisers.</li>
    <li className='mb-2' style={{listStyleType: "lower-roman"}}>. Don’t rush into making investments that do not match your risk taking appetite and
    investment goals.</li>
    <li className='mb-2' style={{listStyleType: "lower-roman"}}>. Do not share login credential and password of your trading and demat accounts
    with the investment Advisor</li>
    </ol>
   </div>
  </div>
  <Footer/>
  </>
  )
}

export default InvestorCharter