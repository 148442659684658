import React from 'react'
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Skeletoncard = () => {
  return (
    <>
     <div style={{ display: "flex", justifyContent: "start", flexWrap: "wrap" }}>
                    <div style={{backgroundColor: "#fff", margin: "8px 5px"}}>
                    <Skeleton width={240} height={180} />
                    <Skeleton width={240} height={10} />
                    <Skeleton width={140} height={10} />
                   
                    <Skeleton width={60} height={20}  style={{float: "left"}}/>
                    </div>
                    <div style={{backgroundColor: "#fff", margin: "8px 5px"}}>
                    <Skeleton width={240} height={180} />
                    <Skeleton width={240} height={10} />
                    <Skeleton width={140} height={10} />
                    
                    <Skeleton width={60} height={20}  style={{float: "left"}}/>
                    </div>
                    <div style={{backgroundColor: "#fff", margin: "8px 5px"}}>
                    <Skeleton width={240} height={180} />
                    <Skeleton width={240} height={10} />
                    <Skeleton width={140} height={10} />
                   
                    <Skeleton width={60} height={20}  style={{float: "left"}}/>
                    </div>
                    <div style={{backgroundColor: "#fff", margin: "8px 5px"}}>
                    <Skeleton width={240} height={180} />
                    <Skeleton width={240} height={10} />
                    <Skeleton width={140} height={10} />
                   
                    <Skeleton width={60} height={20}  style={{float: "left"}}/>
                    </div>
                    <div style={{backgroundColor: "#fff", margin: "8px 5px"}}>
                    <Skeleton width={240} height={180} />
                    <Skeleton width={240} height={10} />
                    <Skeleton width={140} height={10} />
                   
                    <Skeleton width={60} height={20}  style={{float: "left"}}/>
                    </div>
                    <div style={{backgroundColor: "#fff", margin: "8px 5px"}}>
                    <Skeleton width={240} height={180} />
                    <Skeleton width={240} height={10} />
                    <Skeleton width={140} height={10} />
                   
                    <Skeleton width={60} height={20}  style={{float: "left"}}/>
                    </div>
                   
                   
                  </div>
    </>
  )
}

export default Skeletoncard