import React from 'react'
// import { Button, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import FeedBack from 'react-feedback-popup';
// import * as bd from "react-basic-design";

export const Feedback = () => {
  return (
    <div className=' Feedback'>
    <FeedBack
				style={{zIndex:'3', marginLeft:'10px', position:'fixed',backgroundColor:"white",border:"none"}}
				position="Right"
				numberOfStars={5}
				headerText="Have Feedback 📝?"
				bodyText="Give Your Feedback Here..."
				buttonText="Feedback"
				// handleClose={() => console.log("handleclose")}
				// handleSubmit={(data) => 
				// 	fetch('xxxxxx', {
				// 		headers: {
				// 			Accept: 'application/json',
				// 			'Content-Type': 'application/json'
				// 		},
				// 		method: 'POST', // or 'PUT'
				// 		body: JSON.stringify(data),
				// 	}).then((response) => { 
				// 		if (!response.ok) {
				// 			return Promise.reject('Our servers are having issues! We couldn\'t send your feedback!');
				// 		}
				// 		response.json()
				// 	}).then(() => {
				// 		alert('Success!');
				// 	}).catch((error) => {
				// 		alert('Our servers are having issues! We couldn\'t send your feedback!', error);
				// 	})
				// }
				// handleButtonClick={() => console.log("handleButtonClick")}
			/>
 
    </div>


  )
}
