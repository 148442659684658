import React from 'react'
import { RiLogoutBoxRLine } from "react-icons/ri";
import '../NavBar/navbar.css'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../../redux/reducer/reducer';
const LogoutCard = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
const handleLogout = () => {
  dispatch(logout())
  navigate('/')
}


  return (
    <>
    <div className="modal-wrapper">
   
    <div className="logout-confirm">
        <div className="body">
            <span><RiLogoutBoxRLine /></span>
            <p>Are you sure, you want to logout?</p>
        </div>
        <div className="bottom-buttons">
            <button className='no' onClick={() => props.setShowLogout(false)}>No</button>
            <button className='yes' onClick={handleLogout}>Yes</button>
        </div>
    </div>
       
    </div>
    </>
  )
}

export default LogoutCard