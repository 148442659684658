import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { logout } from './redux/reducer/reducer';

const AutoLogout = () => {
    const dispatch = useDispatch()
    const isAuthenticated = useSelector((state) => state.myreducer.isAuthenticated)
    useEffect(() => {
  
      const updateLastActivity = () => {
        if (isAuthenticated) {
            localStorage.setItem('lastActivity', new Date().getTime());
          }
      };
      const events = ['mousemove', 'keydown', 'scroll', 'click'];
      events.forEach(event => window.addEventListener(event, updateLastActivity));
  
      const checkInactivity = () => {
        const lastActivity = localStorage.getItem('lastActivity');
        if (lastActivity && new Date().getTime() - lastActivity > 2 * 24 * 60 * 60 * 1000) {
          localStorage.removeItem('lastActivity');
          // Perform logout actions, such as clearing auth tokens and redirecting to login page
          dispatch(logout())
        }
      };
  
      const interval = setInterval(checkInactivity, 1000 * 60 * 5); // Check every 5 minutes
  
      return () => {
        events.forEach(event => window.removeEventListener(event, updateLastActivity));
        clearInterval(interval);
      };
    }, [dispatch]);
  
    return null;
  };
  



export default AutoLogout