import React, { useState } from "react";
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import data from "../Question.json";
import item from '../Question2.json'
import Navbar from "../components/partial/Navbar";
import Footer from "../components/partial/Footer";
import { Helmet } from "react-helmet-async";
const RiskCalculator = () => {
  const [showFirstForm, setshowFirstForm] = useState(true);
  const [showMidForm, setshowMidForm] = useState(false);
  const [showSecondScreen, setShowSecondScreen] = useState(false);
const [score, setScore] = useState("")
const [userResponses, setUserResponses] = useState([]);
const handleFirst = () => {
  setshowFirstForm(false)
  setshowMidForm(true)
}

const handlePre = () => {
  setShowSecondScreen(false)
  setshowMidForm(true)
}

const handleQues = () => {
  setshowMidForm(false)
  setshowFirstForm(true)
}

const handleAnswerChange = (questionIndex, selectedOption, score) => {
  const updatedResponses = [...userResponses];

  // Update existing response
  updatedResponses[questionIndex] = {
    question: data?.question[questionIndex]?.Question,
    answer: selectedOption,
    marks: score,
  };
  setUserResponses(updatedResponses);
  // console.log(updatedResponses);
};

const handleCalculate = (e) => {
  e.preventDefault();
  setshowMidForm(false)
  setShowSecondScreen(true)
   // Check if all questions are answered

const calculatedTotalScore = userResponses.reduce(
  (total, response) => total + response.marks,
  0
);
if(calculatedTotalScore >= 360 && calculatedTotalScore <= 400){
    setScore("Very Aggressive")
}
else if(calculatedTotalScore >= 260 && calculatedTotalScore <= 350){
  setScore("Aggressive")
}
else if(calculatedTotalScore >= 130 && calculatedTotalScore <= 250){
  setScore("Moderate")
}
else if(calculatedTotalScore >= 80 && calculatedTotalScore <= 120){
  setScore("Defensive ")
}
else{
  setScore("")
}
  
};

  return (
    <>
    <Helmet>
        <title>Risk Profile Calculator - Identify your Risk Apetite | Brighter mind</title>
        <meta name="description" content="Calculate your risk appetite using our Risk Profile Calculator. Answer the few questions and get your risk tolerance report instant. Use Risk Profiler Calculator now!" />
      </Helmet>
    <Navbar/>
      <div className="py-5 contact-header">
        <div className="  ps-5 text-white  ">
          <div>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link> <MdKeyboardArrowRight />
                </li>
                <li className="breadcrumb-item text-white " aria-current="page">
                  Risk Profile Calculator
                </li>
              </ol>
            </nav>
          </div>
          <div>
            <h1>Risk Profile Calculator</h1>
          </div>
        </div>
      </div>
      <div className="container my-3">
        <div className="row">
          <div className="steps">
            <div className="step-arrow"></div>
            <div className="step-area">
              <span className={!showFirstForm ? "box" : "box active-box"}>
                1
              </span>{" "}
              <span>Step 1</span>
            </div>
            <div className="step-area">
              <span className={!showMidForm ? "box" : "box active-box"}>
                2
              </span>{" "}
              <span>Step 2</span>
            </div>
            <div className="step-area">
              <span className={!showSecondScreen ? "box" : "box active-box"}>
                3
              </span>{" "}
              <span>Calculate Risk</span>
            </div>
          </div>
          
        {
          showFirstForm && (
        
            <div className="col-lg-8 mx-auto mt-3">
              <section className="modal-container-body rtf">
                <form action="">
                  {data?.question?.map((res, questionIndex) => {
                    return (
                      <>
                        <div className="questions mb-3" key={res?.id}>
                          <div className="d-flex">
                            <span>{questionIndex + 1}&#41; </span>
                            <p className="mb-2 ms-2">{res?.Question}</p>
                          </div>
                          {res?.Options?.map((i, index) => {
                            return (
                              <div
                                className="d-flex align-items-center mb-2 "
                                key={i?.ansId}
                              >
                                <input
                                  type="radio"
                                  name={`questionOptions_${res?.id}`}
                                  id={`reverseCheck${i?.ansId}`}
                                  value={i?.Option}
                                  onChange={() =>
                                    handleAnswerChange(questionIndex, i.Option, i.score)
                                  }
                                />
                                <label
                                  className="ms-3"
                                 
                                >
                                  {" "}
                                  {i?.Option}
                                </label>
                              </div>
                            );
                          })}
                        </div>
                      </>
                    );
                  })}
                </form>
              </section>
              <div className="mt-4">
              
                <button className="btn btn-primary  text-white  border-0  py-2 px-4 rounded-1  " onClick={handleFirst}
               
                >
                  Next
                </button>
              </div>
            </div>
                
          )
        }
        {
          showMidForm && (
         
            <div className="col-lg-8 mx-auto mt-3">
              <section className="modal-container-body rtf">
                <form action="">
                  {item?.questions?.map((res, questionIndex) => {
                    return (
                      <>
                        <div className="questions mb-3" key={res?.id}>
                          <div className="d-flex">
                            <span>{questionIndex + 5}&#41; </span>
                            <p className="mb-2 ms-2">{res?.Question}</p>
                          </div>
                          {res?.Options?.map((i, index) => {
                            return (
                              <div
                                className="d-flex align-items-center mb-2 "
                                key={i?.ansId}
                              >
                                <input
                                  type="radio"
                                  name={`questionOptions_${res?.id}`}
                                  id={`reverseCheck${i?.ansId}`}
                                  value={i?.Option}
                                  onChange={() =>
                                    handleAnswerChange(questionIndex, i.Option, i.score)
                                  }
                                />
                                <label
                                  className="ms-3"
                                 
                                >
                                  {" "}
                                  {i?.Option}
                                </label>
                              </div>
                            );
                          })}
                        </div>
                      </>
                    );
                  })}
                </form>
              </section>
              <div className="mt-4">
                <button className="btn btn-primary   text-white  border-0  py-2 px-4 rounded-1  " onClick={handleQues}>
                  Previous
                </button>
                <button className="btn btn-primary  text-white  border-0  p-2 rounded-1  ms-lg-3" onClick={handleCalculate}>
                  Calculate Risk
                </button>
              </div>
            </div>
               
          )
        }
        {
          showSecondScreen && (
      
            <div className="col-lg-8 mx-auto mt-3">
              <h4 className=" fw-normal">
                Your Risk Profile is identified as : <strong>{score}</strong>
              </h4>
              <div className="mt-4">
                <button className=" bg-dark-subtle   text-white  border-0  py-2 px-3 rounded-1  " onClick={handlePre}>
                  Previous
                </button>
                <button className="btn btn-primary  text-white  border-0  py-2 px-4 rounded-1  ms-lg-3">
                  Submit
                </button>
              </div>
            </div>
                
                )
              }
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default RiskCalculator;
