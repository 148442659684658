import React, { useEffect, useState } from "react";
import "./Style/portfoliostrategy.css";
import { Feedback } from "./Feedback";
import SideBar from "../Components/SideBar/SideBar";
import TextLinkExample from "../Components/NavBar/NavBar";
import { useContext } from "react";
import { SidebarContext } from "../../context/SidebarContext";
import LogoutCard from "../Components/Card/LogoutCard";
import Footer from "../Components/Footer/Footer";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import { useGetDataQuery } from "../../redux/API/dashboardApi";
import { calculateCurretValue, formatIndianNumber } from "../../Constant/utils";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
} from "chart.js";
import { Line } from "react-chartjs-2";
ChartJS.register(LineElement, PointElement, LinearScale, Title, CategoryScale);
export default function PortfolioStrategy() {
  const { isSiderbaropen } = useContext(SidebarContext);
  const [showLogout, setShowLogout] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const userId = userData?.validateEmail?._id;
  const { data } = useGetDataQuery(userId);
  const [chartData, setChartData] = useState(null);
  const [curretValue, setCurretValue] = useState(null);

  const formatValue = (value) => {
    if (value >= 10000000) {
      return Math.round(value / 10000000) + " Cr";
    } else if (value >= 100000) {
      return Math.round(value / 100000) + " L";
    } else {
      return Math.round(value);
    }
  };
  const formatValue2 = (value) => {
    if (value >= 10000000) {
      return Math.round(value / 10000000) + " Cr";
    } else if (value >= 100000) {
      return Math.round(value / 100000) + " L";
    } else {
      return Math.round(value);
    }
  };

  const yearsArray = [5, 10, 15, 20, 25];
  const percentagesArray = [0.16, 0.2, 0.24];

  useEffect(() => {
    if (data) {
      const calculatedValue = calculateCurretValue(data);
      setCurretValue(calculatedValue);
      const values = yearsArray.flatMap((year) =>
        percentagesArray.map(
          (percentage) => calculatedValue * Math.pow(1 + percentage, year)
        )
      );

      const updatedChartData = {
        labels: yearsArray,
        datasets: percentagesArray.map((percentage, index) => ({
          label: `${(percentage * 100).toFixed(2)}%`,
          data: yearsArray.map(
            (year) => calculatedValue * Math.pow(1 + percentage, year)
          ),
          borderColor: `rgba(${index * 80}, ${99 - index * 30}, ${
            index * 70
          }, 0.8)`,
          fill: false,
          tension: 0.1,
        })),
      };

      setChartData(updatedChartData);
    }
  }, [data]);

  const allData = chartData?.datasets.flatMap((dataset) => dataset.data);
  const minValue = allData ? Math.min(0, ...allData) : 0;

  const options = {
    scales: {
      x: {
        title: {
          display: true,
          text: "(In Years)",
        },
      },
      y: {
        title: {
          display: true,
          text: "(In Rs)",
        },
        beginAtZero: true,
        min: minValue,
        ticks: {
          callback: function (value) {
            return formatValue2(value);
          },
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (context) {
            return formatValue2(context.raw);
          },
        },
      },
    },
  };

  return (
    <>
      <div className="App">
        <SideBar />
        <div className={!isSiderbaropen ? "pages" : "pages-change"}>
          <TextLinkExample setShowLogout={setShowLogout} />
          <div className=" PortfolioStrategy px-2">
            <div className="container mb-3">
              <div className="row gy-4">
               <div className="col-12">
               <div className="UpperBtn PortfolioStrategy2">
              <button className="download-btn mt-2">
                Download Investment Report
              </button>
            </div>
                </div> 
              <div className="col-12">
              <div className="card PortfolioStrategy2">
              <h4 className="mx-2 my-2">Portfolio Strategy</h4>
              <h4
                className="mx-2 my-2"
                style={{
                  backgroundColor: "#9DC913",
                  borderRadius: "4px",
                  color: "white",
                  padding: "5px 10px",
                }}
              >
                {" "}
                E-50 Strategy{" "}
              </h4>
            </div>
              </div>
              <div className="col-12">
                <div className="card py-3">
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-3">
                <div className="strategy-img">
                  <img
                    src={require("../Components/photos/portfoliostrategyimg.png")}
                    alt=""
                  />
                </div>
                </div>
               <div className="col-lg-8">
               <h5>Our Portfolio Strategy:</h5>
                 <p>At Brighter Mind Equity Advisor, we try to blend growth and valuation in such a manner that helps us to get benefits of twin re-rating engines “Earnings & valuation multiples”. We never compromise on quality of companies for the sake of returns. Our focus is to preserve capital and then build ground for the compounding to take care of wealth generation.</p>
                 <p>
                 We believe in holding the companies as long as the company is delivering on the growth higher than industry average and sharing the growth with minority investors in form of buybacks or dividends and valuations does not get to insane levels.
                 </p>
               </div>
              </div>
              </div>
              </div>
              </div>
            </div>
            <div className="container">
              <div className="row gy-3">
                <div className="col-lg-6">
                  <MDBTable
                    hover
                    striped>
                    <MDBTableHead
                      dark
                    >
                      <tr>
                        <th scope="col" className="text-center">Years</th>
                        <th scope="col" className="text-center">16%</th>
                        <th scope="col" className="text-center">20%</th>
                        <th scope="col" className="text-center">24%</th>
                      </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                      {yearsArray.map((years, index) => (
                        <tr key={index}>
                          <td className="text-center" >{years}</td>
                          {percentagesArray.map((percentage, innerIndex) => (
                            <td className="text-center" key={innerIndex}>
                              {curretValue &&
                                formatValue(
                                  curretValue * Math.pow(1 + percentage, years)
                                )}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </MDBTableBody>
                  </MDBTable>
                  <div className=" py-1">
                <p className="mb-1 fw-medium">
                *Above figures are based on your portfolio's current value.</p>

                
                    </div>
                </div>
                <div className="col-lg-6">
                  {chartData && <Line data={chartData} options={options} />}
                </div>
               
              </div>
            </div>
          </div>
          <Footer />
        </div>
        <Feedback />
      </div>
      {showLogout && <LogoutCard setShowLogout={setShowLogout} />}
    </>
  );
}
