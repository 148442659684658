import React from "react";
import Navbar from "../../components/partial/Navbar";
import Footer from "../../components/partial/Footer";
import img1 from "../../images/Shashi-400x400-1.jpg";
import { useAllBlogQuery } from "../../../redux/API/blogApi";
import { Link } from "react-router-dom";
import { formatDate, truncate } from "../../constant/utils";
import { MdOutlineWatchLater } from "react-icons/md";
import { FaPlus } from "react-icons/fa6";

const TeamThree = () => {
  const { data } = useAllBlogQuery();

  function createMarkup2(content) {
    const sanitizedContent = content?.replace(/<p>&nbsp;<\/p>*>/g, "");
    return { __html: sanitizedContent };
  }

  return (
    <>
      <Navbar />
      <section className="mt-3 py-5 border border-t-2 border-b-2 ">
        <div className="container">
          <h4 className="mb-0">Mr. Shashi Kant</h4>
          <p className="fw-semibold text-black-50">
            Principal Officer & Head of Research
          </p>
          <div className="row gy-2 team-detail">
            <div className="col-lg-4">
              <img
                src={img1}
                alt="CA Rajeev Ranjan"
                className="object-fit-contain"
                width={300}
              />
            </div>
            <div className="col-lg-8 team-detail">
              <p>
                Shashi Kant has overall experience of around 9 years in
                Investment Management and Equity Research. Prior to joining
                Brighter Mind Equity Advisors, he has worked with BLB Limited
                and Perfect Research in Proprietary Fund Management and Equity
                Research for Family offices. He has deep domain knowledge in
                equity market covering various sectors and industries. With his
                process driven approach to investing and research, he brings
                unique perspective and insights to the fund management vertical.
                He holds the Masters Degree in Financial Analysis and is a CFA
                (ICFAI) from the Institute of Chartered Financial Analysts of
                India (ICFAI).
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="my-5">
        <div className="container">
          <h3>Blog</h3>
          <div className="row mt-3 gy-3">
            {data &&
              data?.data?.slice(0, 2).map((i) => (
                <div className="col-md-6" key={i?._id}>
                  <div className="card  ">
                    <Link
                      to={i?._id}
                      className=" position-relative latest-update"
                    >
                      <div className="overlay-shadow position-absolute  w-100  h-100  start-0  top-0  align-items-center  justify-content-center  text-white  fs-2">
                        <FaPlus />
                      </div>
                    </Link>
                    <div
                      className="card-body py-3 blog-card-body"
                      style={{ height: "200px" }}
                    >
                      <h5 className="card-title mb-3">
                        {truncate(i?.title, 5)}
                      </h5>
                      <p
                        className="team-blog"
                        dangerouslySetInnerHTML={createMarkup2(
                          truncate(i?.description, 38)
                        )}
                      ></p>
                      <span>
                        <MdOutlineWatchLater />
                        {formatDate(i?.createdAt)}
                      </span>
                      <Link
                        to={`/blog/${i?._id}`}
                        className="read-more-btn float-end py-1 px-2 rounded-2"
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default TeamThree;
