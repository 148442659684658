import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { baseUrl } from "../../Constant/Matcher";

const userToken = JSON.parse(localStorage.getItem("userData"))
const getToken = () => {
    return userToken ? `Bearer ${userToken.token}` : ''
}

export const transectionuplodApi = createApi({
    reducerPath: 'transectionuplodApi',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        mode: "cors",
        prepareHeaders: (headers) => {
            const token = getToken()
            if(token) {
                headers.set('Authorization', token)
            }
            return headers;
        }
    }),
    endpoints : (builder) => ({
        transectionUpload: builder.query({
            query:({bucket, userId}) => ({
                url: `portfolio/clientportfolio?bucket=${bucket}&userid=${userId}`,
                method:"GET",
            })
        }),
        transectionAdvice: builder.query({
            query:( args) => {
                const { userid, adviseid } = args;
                return {
                    url: `portfolio/clientPortfoliodetail?userid=${userid}&adviseid=${adviseid}`,
                    method:"GET",
                }
            }
        }),
    
    })
})

export const { useTransectionUploadQuery, useTransectionAdviceQuery }  = transectionuplodApi