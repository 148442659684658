import React, { useState } from "react";
import Navbar from "../components/partial/Navbar";
import Footer from "../components/partial/Footer";
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import { FaUpload } from "react-icons/fa6";
import logo from "../images/logo (1).png";
import icon from "../images/favicon.png";
const SiteSetting = () => {
  const [selectedImage, setSelectedImage] = useState({
    site_logo: "",
    site_icon: "",
  });
  const [infoData, setInfoData] = useState({
    company_name: "Brighter Mind",
    registation_no: "INA100016363",
    company_email: "support@brightermind.in",
    company_ph_no: "+91-8800308006",
    registered_office: "53-B, U.G.Floor, Vijay Block, Laxmi Nagar, New Delhi - 110092",
    corporate_office: "305, Tower 4, Assotech Business Cresterra, Sector-135, Noida–201304",
    copy_right: "BrightMind.in ",
    facebook: "",
    linkedin: "",
    instagram: "",
    youtube: "",
    twitter: "",
  });
  const handleImageChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage((prevState) => ({
          ...prevState,
          [name]: reader.result,
        }));
      };

      reader.readAsDataURL(file);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInfoData({ ...infoData, [name]: value });
  };

  return (
    <>
      <Navbar />
      <section className="py-5 contact-header">
        <div className="  ps-5 text-white  ">
          <div>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link> <MdKeyboardArrowRight />
                </li>
                <li className="breadcrumb-item text-white " aria-current="page">
                  Site-Setting
                </li>
              </ol>
            </nav>
          </div>
          <div>
            <h1>Site-Setting</h1>
          </div>
        </div>
      </section>
      <section className="mt-5 container">
        <div className="row gy-3">
          <div className="col-12 p-3" style={{backgroundColor: "#edecea"}}>
            <div className="row">
              <div className="col-md-6 text-center">
                <div className="site_logo_container">
                  <img
                    src={selectedImage.site_logo || logo}
                    alt="Brighter Mind"
                    width={180}
                    height={85}
                  />
                </div>
                <p className="text-center text-black-50 mt-3">
                  Logo Max Size: <span className="text-black">250X120</span>
                </p>
                <div className="position-relative d-flex justify-content-center align-items-center my-4">
                  <input
                    type="file"
                    name="site_logo"
                    id="site_logo"
                    className="d-none"
                    onChange={handleImageChange}
                  />
                  <label
                    htmlFor="site_logo"
                    className="position-absolute top-50 start-50 translate-middle login-btn rounded-2 text-white py-2 px-3 pointer"
                  >
                    <FaUpload /> Upload Logo
                  </label>
                </div>
              </div>
              <div className="col-md-6 text-center">
                <div className="site_logo_container">
                  <img
                    src={selectedImage.site_icon || icon}
                    alt="Brighter Mind"
                  />
                </div>
                <p className="text-center text-black-50 mt-3">
                  Favicon Must be <span className="text-black">64X64</span>
                </p>
                <div className="position-relative d-flex justify-content-center align-items-center my-4">
                  <input
                    type="file"
                    name="site_icon"
                    id="site_icon"
                    className="d-none"
                    onChange={handleImageChange}
                  />
                  <label
                    htmlFor="site_icon"
                    className="position-absolute top-50 start-50 translate-middle login-btn rounded-2 text-white py-2 px-3 pointer"
                  >
                    <FaUpload /> Upload Icon
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="my-5 container card">
        <form className="row gy-3  py-2">
          <h3>General Settings</h3>
          <div className="col-lg-6">
            <div className="row gy-2 align-items-center">
              <div className="col-12 shadow-none text-start align-items-baseline ">
                <label htmlFor="company_name" className="mb-1 fw-medium">
                  Company Name:
                </label>
                <input
                  type="text"
                  className="w-100 border rounded-1 px-2 py-1 "
                  name="company_name"
                  id="company_name"
                  value={infoData.company_name}
                  onChange={handleChange}
                />
              </div>

            </div>
          </div>
          <div className="col-lg-6">
            <div className="row gy-2 align-items-center">
              <div className="col-12 shadow-none text-start align-items-baseline ">
                <label htmlFor="registation_no" className="mb-1 fw-medium">
                  SEBI Reg. No.:
                </label>
                <input
                  type="text"
                  className="w-100 border rounded-1 px-2 py-1 "
                  name="registation_no"
                  id="registation_no"
                  value={infoData.registation_no}
                  onChange={handleChange}
                />
              </div>
              
            </div>
          </div>
          <div className="col-lg-6">
            <div className="row gy-2 align-items-center">
              <div className="col-12 shadow-none text-start align-items-baseline ">
                <label htmlFor="company_email" className="mb-1 fw-medium">
                  Email:
                </label>
                <input
                  type="text"
                  className="w-100 border rounded-1 px-2 py-1 "
                  name="company_email"
                  id="company_email"
                  value={infoData.company_email}
                  onChange={handleChange}
                />
              </div>
             
            </div>
          </div>
          <div className="col-lg-6">
            <div className="row gy-2 align-items-center">
              <div className="col-12 shadow-none text-start align-items-baseline ">
                <label htmlFor="company_ph_no" className="mb-1 fw-medium">
                  Mobile No:
                </label>
                <input
                  type="text"
                  className="w-100 border rounded-1 px-2 py-1 "
                  name="company_ph_no"
                  id="company_ph_no"
                  value={infoData.company_ph_no}
                  onChange={handleChange}
                />
              </div>
             
            </div>
          </div>
          <div className="col-lg-6">
            <div className="row gy-2 align-items-center">
              <div className="col-12 shadow-none text-start align-items-baseline ">
                <label htmlFor="registered_office" className="mb-1 fw-medium">
                  REGISTERED OFFICE:
                </label>
                <input
                  type="text"
                  className="w-100 border rounded-1 px-2 py-1 "
                  name="registered_office"
                  id="registered_office"
                  value={infoData.registered_office}
                  onChange={handleChange}
                />
              </div>
             
            </div>
          </div>
          <div className="col-lg-6">
            <div className="row gy-2 align-items-center">
              <div className="col-12 shadow-none text-start align-items-baseline ">
                <label htmlFor="corporate_office" className="mb-1 fw-medium">
                  CORPORATE OFFICE:
                </label>
                <input
                  type="text"
                  className="w-100 border rounded-1 px-2 py-1 "
                  name="corporate_office"
                  id="corporate_office"
                  value={infoData.corporate_office}
                  onChange={handleChange}
                />
              </div>
              
            </div>
          </div>
          <div className="col-lg-6">
            <div className="row gy-2 align-items-center">
              <div className="col-12 shadow-none text-start align-items-baseline ">
                <label htmlFor="copy_right" className="mb-1 fw-medium">
                  Copy Right:
                </label>
                <input
                  type="text"
                  className="w-100 border rounded-1 px-2 py-1 "
                  name="copy_right"
                  id="copy_right"
                  value={infoData.copy_right}
                  onChange={handleChange}
                />
              </div>
              
            </div>
          </div>
          <div className="col-12">
            <h3>Social Media Links</h3>
          </div>
          <div className="col-lg-6">
            <div className="row gy-2 align-items-center">
              <div className="col-12 shadow-none text-start align-items-baseline ">
                <label htmlFor="facebook" className="mb-1 fw-medium">
                  Facebook:
                </label>
                <input
                  type="text"
                  className="w-100 border rounded-1 px-2 py-1 "
                  name="facebook"
                  id="facebook"
                  value={infoData.facebook}
                  onChange={handleChange}
                />
              </div>
              
            </div>
          </div>
          <div className="col-lg-6">
            <div className="row gy-2 align-items-center">
              <div className="col-12 shadow-none text-start align-items-baseline ">
                <label htmlFor="linkedin" className="mb-1 fw-medium">
                  Linkedin:
                </label>
                <input
                  type="text"
                  className="w-100 border rounded-1 px-2 py-1 "
                  name="linkedin"
                  id="linkedin"
                  value={infoData.linkedin}
                  onChange={handleChange}
                />
              </div>
              
            </div>
          </div>
          <div className="col-lg-6">
            <div className="row gy-2 align-items-center">
              <div className="col-12 shadow-none text-start align-items-baseline ">
                <label htmlFor="instagram" className="mb-1 fw-medium">
                  Instagram:
                </label>
                <input
                  type="text"
                  className="w-100 border rounded-1 px-2 py-1 "
                  name="instagram"
                  id="instagram"
                  value={infoData.instagram}
                  onChange={handleChange}
                />
              </div>
              
            </div>
          </div>
          <div className="col-lg-6">
            <div className="row gy-2 align-items-center">
              <div className="col-12 shadow-none text-start align-items-baseline ">
                <label htmlFor="youtube" className="mb-1 fw-medium">
                  Youtube:
                </label>
                <input
                  type="text"
                  className="w-100 border rounded-1 px-2 py-1 "
                  name="youtube"
                  id="youtube"
                  value={infoData.youtube}
                  onChange={handleChange}
                />
              </div>
              
            </div>
          </div>
          <div className="col-lg-6">
            <div className="row gy-2 align-items-center">
              <div className="col-12 shadow-none text-start align-items-baseline ">
                <label htmlFor="twitter" className="mb-1 fw-medium">
                  Twitter:
                </label>
                <input
                  type="text"
                  className="w-100 border rounded-1 px-2 py-1 "
                  name="twitter"
                  id="twitter"
                  value={infoData.twitter}
                  onChange={handleChange}
                />
              </div>
             
            </div>
          </div>

          <div className="col-12 text-center">
            <button className="login-btn text-white border-0 py-2 px-4 rounded-2">Submit</button>
          </div>
        </form>
      </section>
      <Footer />
    </>
  );
};

export default SiteSetting;
